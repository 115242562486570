import { useContext, useState } from "react";
import { StateContext } from "../../../context";
import { Trans } from "react-i18next";
import { useLocation } from "react-router-dom";

export const isTouchDevice = () => {
  const isMobile = window.matchMedia("(pointer: coarse)").matches; // will be false when using mouse

  return isMobile;
};

const ContrastItems = () => {
  const { contrastDefinition, contrast, setContrast } = useContext(StateContext);

  return (
    <li>
      <b>
        <Trans i18nKey="contrast" />
      </b>

      <div className="env-dropdown-menu-top">
        {Object.keys(contrastDefinition)
          .slice(0, 2)
          .map((item, index) => (
            <button
              className={`label${index + 1} focus-red`}
              onClick={() => setContrast(item)}
              key={index}
              tabIndex={0}
            >
              <img alt="" className="eye-icon" src="/assets/img/eye.svg" />
              {item === contrast && <img alt="" className="tick-icon" src="/assets/img/check1.svg" />}
            </button>
          ))}
      </div>

      <div className="env-dropdown-menu-down">
        {Object.keys(contrastDefinition)
          .slice(2)
          .map((item, index) => (
            <button
              className={`label${3 - index} focus-red`}
              onClick={() => setContrast(item)}
              key={index}
              tabIndex={0}
            >
              <img alt="" className={`eye-icon${index + 1}`} src="/assets/img/eye.svg" />
              {item === contrast && <img alt="" className="tick-icon" src="/assets/img/check1.svg" />}
            </button>
          ))}
      </div>
    </li>
  );
};

const Dropdowns = () => {
  const { language, changeLanguage, languageDefinition, fontSize, setFontSize, fontDefinition } =
    useContext(StateContext);

  const [envDropdownOpen, setEnvDropdownOpen] = useState(false);
  const [langDropdownOpen, setLangDropdownOpen] = useState(false);

  const location = useLocation();

  const toggleDropdown = () => {
    setEnvDropdownOpen(!envDropdownOpen);
  };
  const openDropdown = () => {
    setEnvDropdownOpen(true);
  };
  const closeDropdown = () => {
    setEnvDropdownOpen(false);
  };
  const openLangDropdown = () => {
    setLangDropdownOpen(true);
  };
  const toggleLangDropdown = () => {
    setLangDropdownOpen(!langDropdownOpen);
  };
  const closeLangDropdown = () => {
    setLangDropdownOpen(false);
  };

  const handleLangDropdownInteraction = (event) => {
    event.preventDefault();
    toggleLangDropdown();
  };
  const handleDropdownInteraction = (event) => {
    event.preventDefault();
    toggleDropdown();
  };

  return (
    <div className="header-setting-position">
      <div className="lang-container">
        <div
          className={`lang-dropdown focus-red ${langDropdownOpen ? "open" : ""}`}
          onClick={(event) => {
            isTouchDevice() && handleLangDropdownInteraction(event);
          }}
          tabIndex={0}
          onFocus={() => {
            !isTouchDevice() && openLangDropdown();
          }}
          onBlur={(event) => {
            if (event.relatedTarget && !event.currentTarget.contains(event.relatedTarget)) {
              closeLangDropdown();
            }
          }}
          onMouseEnter={() => {
            if (!isTouchDevice()) {
              openLangDropdown();
            }
          }}
          onMouseLeave={() => {
            if (!isTouchDevice()) {
              closeLangDropdown();
            }
          }}
        >
          <button className="lang-dropdown-btn" tabIndex={-1}>
            <span className="lang-dropdown-value">
              <i className="icon" aria-hidden="true"></i>
              {languageDefinition[language].label ?? "Kļūda"}
            </span>
          </button>
          <ul className="lang-dropdown-menu">
            <li
              style={{ cursor: "pointer" }}
              className="lang-dropdown-item focus-red"
              tabIndex={0}
              onClick={() => {
                if (language === "en") {
                  changeLanguage("lv", location.pathname);
                } else {
                  changeLanguage("en", location.pathname);
                }
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  if (language === "en") {
                    changeLanguage("lv", location.pathname);
                  } else {
                    changeLanguage("en", location.pathname);
                  }
                }
              }}
            >
              {language === "en" ? <Trans i18nKey="langLat" /> : <Trans i18nKey="langEn" />}
            </li>
          </ul>
        </div>
      </div>

      <div className="env-container">
        <div
          className={`env-dropdown focus-red ${envDropdownOpen ? "open" : ""}`}
          onClick={(event) => {
            isTouchDevice() && handleDropdownInteraction(event);
          }}
          onMouseEnter={() => {
            if (!isTouchDevice()) {
              toggleDropdown();
            }
          }}
          onMouseLeave={() => {
            if (!isTouchDevice()) {
              closeDropdown();
            }
          }}
          tabIndex={0}
          onFocus={(event) => {
            !isTouchDevice() && openDropdown();
          }}
          onBlur={(event) => {
            if (event.relatedTarget && !event.currentTarget.contains(event.relatedTarget)) {
              closeDropdown();
            }
          }}
        >
          <button className="env-dropdown-btn" tabIndex={-1}>
            <span className="env-dropdown-value">
              <i className="icon"></i>
              <Trans i18nKey="settings" />
            </span>
            <span className="env-dropdown-label">
              <i className="icon"></i>
              <Trans i18nKey="settings" />
              ...
            </span>
          </button>
          <ul className="env-dropdown-menu">
            <li style={{ pointerEvents: "none", outline: "none", cursor: "default", userSelect: "none" }}>
              <b>
                <Trans i18nKey="fontSize" />
              </b>
            </li>
            {Object.keys(fontDefinition).map((item, index) => (
              <li
                key={index}
                onClick={() => setFontSize(item)}
                className="env-dropdown-menu-item focus-red"
                tabIndex={0}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setFontSize(item);
                  }
                }}
              >
                {fontDefinition[item].label}
                {item === fontSize && (
                  <img
                    alt=""
                    className="env-icon1"
                    src="/assets/img/check1.svg"
                    style={{ marginRight: "5%" }}
                  />
                )}
              </li>
            ))}
            <ContrastItems />
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Dropdowns;
