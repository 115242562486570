import moment from "moment";
import MetadataRows from "./MetadataRows";
import { useTranslation } from 'react-i18next';

const PublicInfo = ({ data }) => {
  const { t } = useTranslation();
  if (!Object.keys(data).length) return null;
  const renderedData = [
    {
      title: t('datasetDataGiver'),
      fields: [data.field_publisher_name ?? ""],
      extra: null,
    },
    {
      title: t('datasetDataGiverEmail'),
      fields: [data.field_publisher_email ?? ""],
      extra: null
    },
    {
      title: t("datasetAddDataGiverWebsite"),
      fields: [],
      extra: null,
      navigateTo: data.field_publisher_website,
      navigateToText: data.field_publisher_website ?? "",
    },
    {
      title: t('datasetNid'),
      fields: [data.nid ?? ""],
      extra: null
    },
    {
      title: t("datasetAddCategory"),
      fields: [data.parent_target_id ?? ""],
      extra: "category",
    },
    {
      // the name of the field is field_content_category_en_dummy but it contains `categories detailed` translated to current language 
      // this is due to quirks in the drupal system wich make field naming difficult
      title: data.field_content_category_en_dummy.includes("|DIVIDER|") ? t("datasetAddSubCategories") : t("datasetAddSubCategory"),
      fields: data.field_content_category_en_dummy ? data.field_content_category_en_dummy.split("|DIVIDER|") : [],
      extra: "category",
    },
    {
      title: t("datasetLegalFramework"),
      fields: [data.field_legal_framework ?? ""],
      extra: null,
    },
    {
      title: t("dataSetUpdated") + ":",
      fields: [data.field_changed_date ? moment(data.field_changed_date).format("DD.MM.YYYY HH:mm") : ""],
      extra: null,
    },
    {
      title: t("datasetAddUpdateFreq"),
      fields: [data.field_update_frequency ?? ""],
      extra: null,
    },
    // {
    //   title: t("datasetAddDataFormat"),
    //   fields: [data.field_field_data_format_syntax ?? "", data.field_data_format_model ?? ""],
    //   extra: null,
    // },
    {
      title: t("datasetScore"),
      fields: [!data?.field_score || data.field_score === "0" ? t("datasetNotRated") : data.field_score],
      extra: null,
    },
    // TODO: add file data fields (if not here then in the MetadataRows component)
    // file_title, filename file_upload_url
  ];

  // LVC-2524 7 punkts
  const tableStyle = {
    paddingTop: 39,
    paddingBottom: 29,
    width: "100%",
    tableLayout: "fixed",
  };

  return (
    <table
      className="card-content-table"
      style={tableStyle}
    >
      <tbody>
        <MetadataRows data={renderedData} />
      </tbody>
    </table>
  );
};

export default PublicInfo;
