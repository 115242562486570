import React, { useContext, useEffect, useState } from "react";
import IesutneModal from "../../../modals/iesutnemodal";
import { AbonetasKopasTableRow } from "../../manaskopas/components/tables/AbonetasKopasTable";
import { StateContext } from "../../../context";
import { Trans } from "react-i18next";
import { t } from "i18next";
import { statusStyle } from "../../manaskopas/components/tables/Table";

const Table = ({ data, handleDelete, currentlyDeletingKeys, refetch, specificData, markSeen }) => {
  const { handleModalOpen } = useContext(StateContext);
  const [modalData, setModalData] = useState({});

  const { contrast } = useContext(StateContext);

  const PORTAL_TYPES = [
    "Atbilde uz lietotāja jautājumu par portālu",
    "Atbilde uz lietotāja kļūdas pieteikumu par portālu",
    "Atbilde uz vispārēju lietotāja pieteikumu par portālu",
  ];

  const DATASET_TYPES = [
    "Atbilde uz lietotāja jautājumu",
    "Atbilde uz lietotāja kļūdas pieteikumu",
    "Atbilde uz vispārēju lietotāja pieteikumu",
  ];

  const renderSenderName = (message_type, sender) => {
    const portal_answer = PORTAL_TYPES.includes(message_type);
    const dataset_answer = DATASET_TYPES.includes(message_type);
    if (portal_answer || (dataset_answer && !sender)) {
      return <Trans i18nKey="system_admin" />;
    }
    return sender || <Trans i18nKey="system" />;
  };

  const decideWhichTitleToUse = (item) => {
    if (item?.field_message_message_type && !item?.field_message_message_type.includes("_")) {
      // the underscore signifies a machine name incoming from drupal
      return item.field_message_message_type;
    }
    if (item?.title_1) {
      return item.title_1;
    }
    return "";
  };

  useEffect(() => {
    if (specificData) {
      const { rows } = specificData;
      // if (rows.length > 0) {
      handleModalOpen("iesutne");
      setModalData(rows[0]);
      // }
    }
  }, [specificData]);

  return (
    <>
      <IesutneModal data={modalData} refetch={refetch} renderSenderName={renderSenderName} />
      <div className="manaskopas-main" style={{ marginBottom: 100 }}>
        <div style={{ overflowX: "auto", borderRadius: 4 }}>
          {data.length ? (
            <table className="manaskopas-main-tb1">
              <thead className="manaskopas-main-tb1-tr1">
                <tr>
                  <th className="manaskopas-main-tb1-tr1-th1" style={{ width: 194, marginLeft: 47 }}>
                    <span style={{ marginLeft: 48, marginRight: 48 }}>
                      <Trans i18nKey="date" />
                    </span>
                  </th>
                  <th>
                    <span className="manaskopas-main-tb1-tr1-th1-font1" style={{ width: 600 }}>
                      <Trans i18nKey="topic" />
                    </span>
                  </th>
                  <th>
                    <span className="manaskopas-main-tb1-tr1-th1-font1" style={{ marginLeft: 47 }}>
                      <Trans i18nKey="from" />
                    </span>
                  </th>
                  <th>
                    <span
                      className="manaskopas-main-tb1-tr1-th1-font1"
                      style={{ marginLeft: 47, marginRight: 46 }}
                    >
                      <Trans i18nKey="status" />
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <AbonetasKopasTableRow
                    key={index}
                    item={item}
                    col1={
                      <td style={{ paddingLeft: 48 }}>
                        <div className="manaskopas-main-tb1-tr2-td3">
                          <div className="manaskopas-main-tb1-tr2-td3-left" style={{ marginLeft: 0 }}>
                            <span>{item.created}</span>
                          </div>
                        </div>
                      </td>
                    }
                    col2={
                      <td style={{ paddingLeft: 17 }}>
                        <div
                          tabIndex={0}
                          className="focus-red"
                          onClick={() => {
                            handleModalOpen("iesutne");
                            setModalData(item);
                            if (!item?.field_seen_by) {
                              markSeen(item);
                            }
                          }}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            height: 100,
                            alignItems: "center",
                            width: 400,
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              handleModalOpen("iesutne");
                              setModalData(item);
                              if (!item?.field_seen_by) {
                                markSeen(item);
                              }
                            }
                          }}
                        >
                          <span className="manaskopas-main-tb1-tr2-td1-font" style={{ paddingLeft: 17 }} >
                            {decideWhichTitleToUse(item)}
                          </span>
                        </div>
                      </td>
                    }
                    col3={
                      <td style={{ width: 290 }}>
                        <div
                          className="manaskopas-main-tb1-tr2-td3"
                          style={{ marginLeft: 47, marginRight: 46 }}
                        >
                          <div className="manaskopas-main-tb1-tr2-td3-left">
                            <span>
                              {renderSenderName(item.field_message_message_type, item.field_sender)}
                            </span>
                          </div>
                        </div>
                      </td>
                    }
                    col4={
                      <td style={{ wminWdth: 208 }}>
                        {item.status === "Izmēģinājumā" ? (
                          <div
                            className="manaskopas-main-tb2-tr2-td2"
                            style={{ marginLeft: 47, marginRight: 46 }}
                          >
                            <button className="manaskopas-main-tb2-tr2-td2-btn">
                              <span className="manaskopas-main-tb2-tr2-td2-btn-font">
                                <b>{item.status}</b>
                              </span>
                            </button>
                          </div>
                        ) : item?.field_substatus ? (
                          <div className="manaskopas-main-tb1-tr2-td2">
                            <button
                              className="manaskopas-main-tb1-tr2-td2-btn"
                              style={statusStyle("edit", contrast)}
                            >
                              <span className="manaskopas-main-tb1-tr2-td2-btn-font">
                                <b>{item.field_substatus.toLocaleUpperCase()}</b>
                              </span>
                            </button>
                          </div>
                        ) : (
                          ""
                        )}
                      </td>
                    }
                    col5={
                      <td style={{ width: 200 }}>
                        <div
                          className="manaskopas-main-tb1-tr2-td3-right"
                          style={{
                            paddingLeft: 48,
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          {item.status === "Izpildē" && (
                            <div
                              className="manaskopas-main-tb1-tr2-td3-right-part1"
                              onClick={() => handleModalOpen("iesutne")}
                            >
                              <img
                                alt="alt"
                                style={{ height: 18, width: 18, marginRight: 17 }}
                                src="/assets/img/reply.svg"
                              />
                              <span className="manaskopas-main-tb1-tr2-td3-right-part2-font2">Atbildēt</span>
                            </div>
                          )}
                          {currentlyDeletingKeys?.includes(item?.nid) ? (
                            <div
                              className="loader"
                              style={{
                                width: 20,
                                height: 20,
                              }}
                            />
                          ) : (
                            <img
                              onClick={() => handleDelete(item)}
                              alt="alt"
                              className="manaskopas-main-tb1-tr2-td3-right-part2-sub2 focus-red"
                              tabIndex={0}
                              src="/assets/img/bin.svg"
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  handleDelete(item);
                                }
                              }}
                            />
                          )}
                        </div>
                      </td>
                    }
                  />
                ))}
              </tbody>
            </table>
          ) : (
            <div className="empty-message-container">
              <img src="/assets/img/errorguy2.png" alt="No Messages" className="empty-message-icon" />
              <span className="empty-message-text">{t("emptyMessageText")}</span>
              <span className="empty-message-suggestion">{t("emptyMessageSuggestion")}</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Table;
