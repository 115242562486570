import AuthModal from "../../../modals/authmodal";
import { useNavigate } from "react-router-dom";
import { auth } from "../../../classes/auth";
import { usr as user } from "../../../classes/user";
import { Trans } from "react-i18next";
import Dropdowns, { isTouchDevice } from "./dropdown";
import { useQuery } from "@tanstack/react-query";
import { useContext, useState } from "react";
import { StateContext } from "../../../context";
import { t } from "i18next";
import { getCms } from "../../../classes/cmsApi";

const User = () => {
  const navigate = useNavigate();
  const { language } = useContext(StateContext);

  const [isAuthMenuOpen, setAuthMenuOpen] = useState(false);

  const navigateToMessages = () => navigate("/messages");

  const { data: unreadCount, isLoading } = useQuery(
    ["unreadMessages", language, user.sub],
    async () => {
      if (auth.isAuthorized()) {
        const res = await getCms(`api/v1/user/messages/unread`, language);
        return res.count;
      } else {
        return -1;
      }
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  function openAuthMenu() {
    setAuthMenuOpen(true);
  }

  function closeAuthMenu() {
    setAuthMenuOpen(false);
  }

  function switchAuthMenuOpenState(event) {
    event.preventDefault();
    setAuthMenuOpen(!isAuthMenuOpen);
  }


  function TruncateString({ text }) {
    if (!text) return null;
    const truncatedText = text?.length > 8 ? text.substring(0, 8) + "..." : text;

    return <span className="header-setting-down-auth-personal-container-font1">{truncatedText}!</span>;
  }

  const messagesPermissions = [
    "Paziņojumi administratoram iesūtnē",
    "Paziņojumi datu ņēmējiem",
    "Paziņojumi datu devējiem",
  ];

  const navigateToLink = (url) => {
    if (isTouchDevice()) {
      closeAuthMenu();
    }
    navigate(url);
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Dropdowns />
      </div>
      {auth.isAuthorized() ? (
        <div className="header-setting">
          <div className="header-setting-down-auth-personal">
            {/* sveiki user */}
            <div
              className="header-setting-down-auth-personal-container"
              onClick={() => navigate("/dashboard")}
            >
              {/* PROFILE PICTURE */}
              <div style={{ cursor: "pointer", zIndex: 1 }} onClick={() => navigate("/dashboard")}>
                <img alt="alt" className="header-setting-down-auth-personal-icon" src="/assets/img/sun.png" />
              </div>
              {/* USERNAME */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  marginLeft: "-25px",
                }}
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    navigate("/dashboard");
                  }
                }}
              >
                <span className="header-setting-down-auth-personal-container-font">
                  {t("menuGreeting")}
                  {user.displayProfileName() ? `,` : ""}
                </span>
                <div
                  style={{
                    marginTop: "-3px",
                  }}
                >
                  <TruncateString text={user.displayProfileName()} />
                </div>
              </div>
            </div>
            {/* mail */}
            <div className="header-setting-down-auth-personal-container1">
              <div style={{ display: "flex", paddingTop: "5px" }}>
                <img
                  alt="alt"
                  className="header-setting-down-auth-personal-icon1"
                  src="/assets/img/mail.svg"
                />
              </div>
              <div style={{ paddingLeft: "10px", textAlign: "left" }}>
                <div
                  className="focus-red"
                  onFocus={() => {
                    const dropdown = document.querySelector(".env-dropdown");
                    dropdown.classList.remove("open");
                  }}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      navigateToMessages();
                    }
                  }}
                  tabIndex={0}
                  onClick={navigateToMessages}
                  style={{ display: "block", textDecoration: "none" }}
                >
                  <span className="header-setting-down-auth-personal-container1-font">
                    {isLoading ? (
                      <div
                        className="loader-container"
                        style={{
                          display: "flex",
                          justifyContent: "left",
                          padding: 2,
                        }}
                      >
                        <div
                          className="loader"
                          style={{
                            width: 20,
                            height: 20,
                          }}
                        ></div>
                      </div>
                    ) : unreadCount > 0 ? (
                      unreadCount
                    ) : (
                      <Trans i18nKey="noMessagesNo" />
                    )}
                  </span>
                </div>
                <div onClick={navigateToMessages} style={{ display: "block", textDecoration: "none" }}>
                  <span className="header-setting-down-auth-personal-container1-font1">
                    <Trans
                      i18nKey={`noMessagesMessages${
                        !unreadCount
                          ? ""
                          : (language === "lv" && unreadCount !== 11 && unreadCount % 10 === 1) ||
                            (language === "en" && unreadCount === 1)
                          ? "One"
                          : "Many"
                      }`}
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* lietotaja iespejas un iziet pogas */}
          <div className="header-setting-down-auth-personal-container2" style={{ paddingTop: "10px" }}>
            <div
              className="header-setting-down-auth-personal-container3 focus-red"
              onMouseEnter={() => {
                openAuthMenu()
              }
              }
              onMouseLeave={closeAuthMenu}
              tabIndex={-1}
              onFocus={() => {
                !isTouchDevice() && openAuthMenu();
              }}
              onBlur={(event) => {
                if (!event.currentTarget.contains(event.relatedTarget)) {
                  closeAuthMenu();
                }
              }}
            >
              <button
                className="header-setting-down-auth-personal-container2-button focus-red"
                type="button"
                onClick={(event) => {
                  isTouchDevice() ? switchAuthMenuOpenState(event) : navigateToLink("/dashboard");
                }}
              >
                <img
                  alt="alt"
                  className="header-setting-down-auth-personal-container2-menu header-setting-down-auth-personal-container2-menu-main-icon"
                  src="/assets/img/menu1.svg"
                />
                <p className="header-setting-down-auth-personal-container2-font">
                  <Trans i18nKey="menuProfileFeatures" />
                </p>
              </button>
              <div id="authmenu" style={{ display: isAuthMenuOpen ? "block" : "none" }}>
                <div className="header-setting-down-auth-personal-container2-menudrop">
                  {user?.permissions?.some((item) => item === "Datu devēja publicētās datu kopas") && (
                    <div
                      id="test"
                      tabIndex={-1}
                      onClick={() => navigateToLink("/manaskopas/list")}
                      style={{ cursor: "pointer" }}
                    >
                      <button className="header-setting-down-auth-personal-container2-button4" type="button">
                        <img
                          alt="alt"
                          className="header-setting-down-auth-personal-container2-menu"
                          src="/assets/img/wire.svg"
                        />
                        <p className="header-setting-down-auth-personal-container2-font">
                          <Trans i18nKey="workspacePublishedData" />
                        </p>
                      </button>
                    </div>
                  )}
                  {user?.permissions?.some((item) => item === "Datu ņēmēja abonētās  datu kopas") && (
                    <div onClick={() => navigateToLink("/abonetaskopas")} style={{ cursor: "pointer" }}>
                      <button className="header-setting-down-auth-personal-container2-button4 focus-red" type="button">
                        <img
                          alt="alt"
                          className="header-setting-down-auth-personal-container2-menu"
                          src="/assets/img/wire.svg"
                        />
                        <p className="header-setting-down-auth-personal-container2-font">
                          <Trans i18nKey="workspaceSubscribedData" />
                        </p>
                      </button>
                    </div>
                  )}
                  {user?.permissions?.some((item) => item === "Datu devēja apliecinājumi") && (
                    <div onClick={() => navigateToLink("/apliecinajumi")} style={{ cursor: "pointer" }}
                      className="focus-red">
                      <button className="header-setting-down-auth-personal-container2-button4" type="button">
                        <img
                          alt="alt"
                          className="header-setting-down-auth-personal-container2-menu"
                          src="/assets/img/markcheck.svg"
                        />
                        <p className="header-setting-down-auth-personal-container2-font">
                          <Trans i18nKey="workspaceDeclarationsShort" />
                        </p>
                      </button>
                    </div>
                  )}
                  {user?.permissions?.some((item) => messagesPermissions.includes(item)) && (
                    <div onClick={() => navigateToLink("/messages")} style={{ cursor: "pointer" }}>
                      <button className="header-setting-down-auth-personal-container2-button4" type="button">
                        <img
                          alt="alt"
                          className="header-setting-down-auth-personal-container2-menu"
                          src="/assets/img/mail1.svg"
                        />
                        <p className="header-setting-down-auth-personal-container2-font">
                          <Trans i18nKey="dropdownInbox" />
                        </p>
                      </button>
                    </div>
                  )}
                  <div style={{ cursor: "pointer" }}>
                    <button
                      onClick={() =>
                        navigateToLink(
                          `/palidziba?searchWord=${
                            user?.can("API dokumentācija (datu devējam)")
                              ? t("publishData")
                              : t("subAndManageData")
                          }`
                        )
                      }
                      className="header-setting-down-auth-personal-container2-button4"
                      type="button"
                    >
                      <img
                        alt="alt"
                        className="header-setting-down-auth-personal-container2-menu"
                        src="/assets/img/dev.svg"
                      />
                      <p className="header-setting-down-auth-personal-container2-font">
                        <Trans i18nKey="dropdownDocumentation" />
                      </p>
                    </button>
                  </div>
                  {user.sub && (
                    <button
                      className="header-setting-down-auth-personal-container2-button3"
                      type="button"
                      onClick={() => navigateToLink("/profile")}
                    >
                      <img
                        alt="alt"
                        className="header-setting-down-auth-personal-container2-menu"
                        src="/assets/img/user.svg"
                      />
                      <p className="header-setting-down-auth-personal-container2-font">
                        <Trans i18nKey="dropdownMyProfile" />
                      </p>
                    </button>
                  )}
                </div>
              </div>
            </div>
            <button
              className="default-secondary-button default-secondary-button-cancel focus-red"
              type="button"
              onClick={() => {
                auth.logout();
                navigate(`/${language}`);
              }}
            >
              <p>
                <Trans i18nKey="logout" />
              </p>
              <img alt="alt" className="default-secondary-button-cancel-icon" src="/assets/img/Cancel.svg" />
            </button>
          </div>
        </div>
      ) : (
        <div className="auth-button-container">
          <AuthModal
            trigger={
              <div className="header-setting-down" style={{ marginTop: "60px" }}>
                <div className="header-setting-down-auth">
                  <button className="header-setting-down-auth-button focus-red" type="button">
                    <img
                      alt="alt"
                      className="header-setting-down-auth-button-icon"
                      src="/assets/img/key.svg"
                    />
                    <p className="header-setting-down-auth-button-text">
                      <Trans i18nKey="authorize" />
                    </p>
                  </button>
                </div>
              </div>
            }
            // redirectTo={"/dashboard"}
          />
        </div>
      )}
    </>
  );
};

export default User;
