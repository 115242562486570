import { useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { isTouchDevice } from "../../../common/header/components/dropdown";

export const AccordItem = ({ item, tabOpened, helpOpened, setHelpOpened, onMenuItemClick }) => {
  return (
    <>
      <div
        className="palidzibas-main-accordion-title menu-item active focus-red"
        id={`accord-item-${item.id}`}
        tabIndex={0}
        onClick={onMenuItemClick}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onMenuItemClick();
          }
        }}
      >
        <img
          alt=""
          className={`palidzibas-main-accordion-title-icon ${
            item.id === tabOpened ? "open-help-category" : ""
          }`}
          src={`/assets/img/${item.id !== tabOpened ? "plus" : "minus"}.svg`}
        />
        <span>{item.title}</span>
      </div>
      <Description
        setHelpOpened={setHelpOpened}
        helpOpened={helpOpened}
        data={item.data}
        opened={item.id === tabOpened}
        tabOpened={tabOpened}
      />
    </>
  );
};

const Description = ({ setHelpOpened, helpOpened, data, opened, tabOpened }) => {
  const [, setSearchParams] = useSearchParams();
  const accordionRef = useRef(null);

  const handleNavigate = (nid) => {
    setHelpOpened(nid);
    setSearchParams({});
  };

  useEffect(() => {
    const accordion = accordionRef.current;

    const handleTransitionEnd = () => {
      if (isTouchDevice()) {
        // handle scroll to individual item on mobile
        const element = document.getElementById(`accord-item-${tabOpened}`);
        if (!element) return;

        const elementPosition = element.getBoundingClientRect().top + window.scrollY;

        const mobileAdjustment = 70;

        window.scrollTo({ top: elementPosition - mobileAdjustment, behavior: "smooth" });
      } else {
        // handle scroll to top of the accordion on desktop
        const element = document.getElementById(`palidzibas-main`);
        if (!element) return;

        element.scrollIntoView({
          behavior: "smooth",
        });
      }
    };

    if (opened) {
      const contentHeight = accordion.scrollHeight;
      accordion.style.height = contentHeight + "px";
    } else {
      accordion.style.height = "0";
    }

    if (opened) {
      handleTransitionEnd();
    }
  }, [opened, tabOpened]);

  return (
    <div ref={accordionRef} className={`palidzibas-main-accordion-description ${opened ? "open" : "closed"}`}>
      {data?.map((item) => (
        <div key={item.nid}>
          <div
            className={`palidzibas-main-accordion-description-subtitle submenu-item active ${
              helpOpened === item?.nid ? "selected" : ""
            } ${item?.sub.length === 0 ? "infertile focus-red" : ""}`}
            tabIndex={item?.sub.length === 0 && opened ? 0 : -1}
            onClick={() => {
              if (item?.sub.length === 0) handleNavigate(item.nid);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (item?.sub.length === 0) handleNavigate(item.nid);
              }
            }}
          >
            <img alt="alt" src="/assets/img/list-icon.svg" />
            <span className="palidzibas-main-accordion-description-subtitle-font">{item.title}</span>
          </div>
          {item?.sub?.map((subitem) => (
            <div
              className={`palidzibas-main-accordion-description-subdescription ${
                helpOpened === subitem?.nid ? "selected" : "focus-red"
              }`}
              key={subitem.nid}
              tabIndex={helpOpened === subitem?.nid || !opened ? -1 : 0}
              onClick={() => {
                handleNavigate(subitem.nid);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleNavigate(subitem.nid);
                }
              }}
            >
              <img
                alt="alt"
                className="palidzibas-main-accordion-description-subdescription-icon"
                src="/assets/img/enter.svg"
              />
              <span className="palidzibas-main-accordion-description-subdescription-font">
                {subitem.title}
              </span>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};
