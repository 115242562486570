import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { StateContext } from "../../../context";
import CheckBox from "../../../common/checkbox";
import { getCms } from "../../../classes/cmsApi";
import { useQuery } from "@tanstack/react-query";
import { Trans, useTranslation } from "react-i18next";
import "./index.css";
import { useSearchParams } from "react-router-dom";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import { t } from "i18next";
import moment from "moment";
import { translateValues } from "../../manaskopas/components/overalldata";
import { xmlSyntax } from "../../manaskopas/components/formitems/dataformat";
import { StyledSelect } from "../../manaskopas/components/styledReactSelect";

const emptyOptions = {
  metadataCategories: [],
  metadataSubCategories: [],
  dataFormats: [],
  dataModel: [],
  eventTypes: [],
  languages: [],
  esRegulas: [],
  users: [],
};

const getDropdownValues = async (language) => {
  try {
    // nested data categories
    const respCategories = await getCms("public/data-categories", language);

    const removeDuplicates = (arr, key) => {
      const seen = new Set();
      return arr.filter((item) => {
        const value = item[key][0].value;
        if (seen.has(value)) {
          return false;
        } else {
          seen.add(value);
          return true;
        }
      });
    };

    const uniqueCategories = removeDuplicates(respCategories, "tid");

    const buildHierarchy = (terms) => {
      const hierarchy = [];

      terms.forEach((term) => {
        if (!term.parent[0].target_uuid) {
          const parentTerm = {
            key: term.tid[0].value,
            value: term.name[0].value,
            children: [],
          };

          terms.forEach((innerTerm) => {
            if (innerTerm.parent[0].target_id === parentTerm.key) {
              const childTerm = {
                key: innerTerm.tid[0].value,
                value: innerTerm.name[0].value,
              };
              parentTerm.children.push(childTerm);
            }
          });

          hierarchy.push(parentTerm);
        }
      });

      return hierarchy;
    };

    const nestedTaxonomy = buildHierarchy(uniqueCategories);

    // users
    // const respUsers = await getCms("public/user/list");
    // const users = respUsers.map((user) => ({ key: user.uid, value: user.name }));

    // taxonomies and Text(Lists)
    const resp = await getCms("public/metadata-dropdown/list", language);

    const data = {
      metadataCategories:
        nestedTaxonomy?.map((category) => ({
          key: category.key,
          value: category.value,
          children: category.children,
        })) ?? [],
      dataFormats: translateValues(resp.field_field_data_format_syntax ?? []),
      datexModelTerms: resp.term_data_format_model.filter((item) => item?.isDatex).map((item) => item.key),
      dataModel: resp.term_data_format_model ?? [],
      eventTypes: [], // dont know what is this
      languages: translateValues(resp.field_language_content ?? []),
      esRegulas: translateValues(resp.regula ?? []),
    };
    return data;
  } catch (err) {
    console.error(err);
    return emptyOptions;
  }
};

const useSort = (setFilter) => {
  const { t } = useTranslation();
  const { language } = useContext(StateContext);
  const prevLanguage = useRef(language);

  const [params] = useSearchParams();
  const sort_by = useMemo(() => params.get("sort_by"), [params]);

  const [defaultKey, defaultDirection] = useMemo(() => {
    if (!sort_by) return ["field_changed_date_value", "desc"];
    const splitSort = sort_by.split("_");
    const direction = splitSort.pop();
    const key = splitSort.join("_");

    return [key, direction];
  }, [sort_by]);

  const [selected, setSelected] = useState(defaultKey);
  const [sort, setSort] = useState(defaultDirection);

  const options = useMemo(
    () => [
      {
        title: t("generalSortByTitle"),
        key: language === "lv" ? "field_dataset_name_lv_value" : "field_dataset_name_value",
      },
      {
        title: t("generalSortByDateCreated"),
        key: "created",
      },
      {
        title: t("generalSortByDateUpdated"),
        key: "field_changed_date_value",
      },
      {
        title: t("generalSortByUser"),
        key: "username",
      },
    ],
    [language, t]
  );

  const handleSort = useCallback(
    ({ key, direction }) => {
      setSort(direction);
      setSelected(key);
      setFilter({ sort_by: `${key}_${direction}` });
    },
    [setFilter]
  );

  useEffect(() => {
    if (prevLanguage.current === language) return;
    prevLanguage.current = language;

    if (selected.includes("field_dataset_name")) {
      const newKey = language === "lv" ? "field_dataset_name_lv_value" : "field_dataset_name_value";
      handleSort({ key: newKey, direction: sort });
    }
  }, [handleSort, language, selected, sort]);

  return [options, selected, sort, handleSort];
};

const useCheckboxes = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams(); // Get search params
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

  const checkboxes = useMemo(
    () => [
      {
        id: 1,
        title: t("generalOnlyApi"),
        key: "field_distribution_mode",
        param: "api",
      },
      {
        id: 2,
        title: t("generalRecents"),
        key: "field_changed_date_value",
        param: "-12 months",
      },
    ],
    [t]
  );

  useEffect(() => {
    const newSelectedCheckboxes = checkboxes
      .filter((checkbox) => searchParams.get(checkbox.key) === checkbox.param)
      .map((checkbox) => checkbox.id);

    setSelectedCheckboxes(newSelectedCheckboxes);
  }, [searchParams, checkboxes]);

  return [checkboxes, selectedCheckboxes, setSelectedCheckboxes];
};

export const useDropdowns = () => {
  const { t } = useTranslation();
  const [selectedOptions, setSelectedOptions] = useSearchParams();
  const { language } = useContext(StateContext);

  const {
    data: {
      metadataCategories,
      dataFormats,
      datexModelTerms,
      dataModel,
      languages,
      esRegulas,
    },
    isLoading,
    isFetching
  } = useQuery({
    queryKey: ["dataset-filter-dropdownValues", language],
    queryFn: () => getDropdownValues(language),
    placeholderData: emptyOptions,
    refetchOnWindowFocus: false,
  });

  const metadataSubCategories = useMemo(() => {
    if (!selectedOptions.get("field_content_category_parent_target_id")) return [];
    return (
      metadataCategories.find(
        (category) => `${category.key}` === selectedOptions.get("field_content_category_parent_target_id")
      )?.children ?? []
    );
  }, [metadataCategories, selectedOptions]);

  const filteredDataFormats = useMemo(() => {
    const selectedModel = selectedOptions.get("field_format_model_value");

    // filter out syntax options selected model doesnt support
    if (selectedModel && datexModelTerms?.includes(selectedModel)) {
      return [xmlSyntax];
    }
    return dataFormats;
  }, [dataFormats, selectedOptions, datexModelTerms]);

  useEffect(() => {
    const selectedModel = selectedOptions.get("field_format_model_value");
    const selectedSyntax = selectedOptions.get("field_field_data_format_syntax_value");

    if (!selectedSyntax || !selectedModel) return;
    // Reset the selected syntax if model doesnt support it
    if (datexModelTerms?.includes(selectedModel) && selectedSyntax !== xmlSyntax.key) {
      const updatedOptions = new URLSearchParams(selectedOptions);
      updatedOptions.delete("field_field_data_format_syntax_value");
      setSelectedOptions(updatedOptions);
    }
  }, [datexModelTerms, selectedOptions, setSelectedOptions]);

  const dropdowns = useMemo(
    () => [
      {
        title: t("generalAllCategories"),
        image: "/assets/img/visas.svg",
        key: "field_content_category_parent_target_id",
        items: [...metadataCategories],
      },
      ...(metadataSubCategories.length > 0
        ? [
            {
              title: t("generalAllSubCategories"),
              image: "/assets/img/visas.svg",
              key: "field_content_category_target_id",
              items: [...metadataSubCategories],
            },
          ]
        : []),
      {
        title: t("generalAllDataFormats"),
        image: "/assets/img/note.svg",
        key: "field_field_data_format_syntax_value",
        items: [...filteredDataFormats],
      },
      {
        title: t("generalAllDataModels"),
        image: null,
        key: "field_format_model_value",
        items: [...dataModel],
      },
      {
        title: t("allEURegulas"),
        key: "field_legal_framework_value",
        items: [...esRegulas],
      },
    ],
    [t, metadataCategories, metadataSubCategories, filteredDataFormats, dataModel, esRegulas]
  );

  return { dropdowns, isLoading, isFetching };
};

const MapToggle = ({ setFilter }) => {
  const [checked, setChecked] = useState(false);
  const [searchParams] = useSearchParams();

  const handleClick = () => {
    setFilter({ field_link_to_map_uri: checked ? undefined : "0" }); 
    setChecked((current) => !current);
  };

  useEffect(() => {
    const checked = searchParams.get("field_link_to_map_uri") === "0";
    setChecked(checked);
  }, [searchParams]);

  return (
    <div className="catalog-main-filter-option">
      <div className="catalog-main-filter-option-text">
        <Trans i18nKey="generalFiltersAll" />
      </div>
      <div
        className="catalog-main-filter-option-checkbox focus-red"
        tabIndex={0}
        onClick={handleClick}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleClick();
          }
        }}
      >
        <div
          className={`catalog-main-filter-option-checkbox-switcher catalog-main-filter-option-checkbox-check${
            checked ? "T" : "F"
          }`}
        >
          <div className="catalog-main-filter-option-checkbox-btn"></div>
        </div>
      </div>

      <div className="catalog-main-filter-option-text">
        <Trans i18nKey="generalFiltersWithMap" />
      </div>
    </div>
  );
};


const SortOrder = ({ onClick, direction }) => {
  const rotationStyle = useMemo(() => {
    return direction === "asc" ? { transform: "rotate(-90deg)" } : { transform: "rotate(90deg)" };
  }, [direction]);

  return (
    <div
      className="sort-order-btn focus-red"
      onClick={onClick}
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          onClick();
        }
      }}
    >
      <img
        alt="alt"
        className="profile-main-left-linkform-link-icon-blue"
        src="/assets/img/next.svg"
        style={{
          ...rotationStyle,
          margin: 0,
          width: "1.5em",
          height: "1.5em",
          transition: "transform 0.3s cubic-bezier(.68,-0.35,.27,1)"
        }}
      />
    </div>
  );
};

const Sort = ({ setFilter }) => {
  const [options, selected, sort, handleSort] = useSort(setFilter);
  return (
    <>
      <div className="catalog-main-filter-group">
        <div className="catalog-main-sort-group">
        <div style={{width: '100%'}}>
          <StyledSelect
            isSearchable
            notClearable
            value={options.find((i) => i.key === selected)}
            options={options}
            onChange={(selected) => {
              handleSort({ key: selected?.key, direction: sort });
            }}
            getOptionLabel={(option) => option.title}
            getOptionValue={(option) => option.key}
          />
          </div>

          <SortOrder
            onClick={() => handleSort({ key: selected, direction: sort === "asc" ? "desc" : "asc" })}
            direction={sort}
          />
        </div>
      </div>
    </>
  );
};

const Dropdowns = ({ dropdowns, filters, setFilter }) => {
  return (
    <div className="catalog-main-filter-group">
      {dropdowns.map((item) => {
        return (
          <StyledSelect
            isSearchable
            fieldName={item.key}
            placeholder={t(item.title)}
            value={item.items.find((i) => `${i.key}` === filters.get(item.key)) || null}
            options={item.items}
            onChange={(selected) => {
              if (!selected && item.key === "field_content_category_parent_target_id") {
                const newFilter = filters.delete("field_content_category_target_id");
                setFilter(newFilter ?? {});
              }
              if (!selected) {
                const newFilter = filters.delete(item.key);
                setFilter(newFilter ?? {});
                return;
              }
              setFilter({ [item?.key]: selected.key });
            }}
            getOptionLabel={(option) => option.value}
            getOptionValue={(option) => option.key}
            isClearable
          />
        );
      })}
    </div>
  );
};

const CheckBoxes = ({ setFilter }) => {
  const [checkboxes, selectedCheckboxes, setSelectedCheckboxes] = useCheckboxes();

  const handleCheckboxes = useCallback(
    (id) => {
      const selected = checkboxes.find((item) => item.id === id);
      if (!selected) return;
      setSelectedCheckboxes((prevSelectedCheckboxes) => {
        const index = prevSelectedCheckboxes.indexOf(id);
        if (index !== -1) {
          const updatedCheckboxes = [...prevSelectedCheckboxes];
          updatedCheckboxes.splice(index, 1);
          setFilter({ [selected.key]: undefined });
          return updatedCheckboxes;
        } else {
          setFilter({ [selected.key]: selected.param });
          return [...prevSelectedCheckboxes, id];
        }
      });
    },
    [checkboxes, setFilter, setSelectedCheckboxes]
  );

  return (
    <div className="catalog-main-filter-group">
      {checkboxes.map((item, index) => (
        <CheckBox
          key={index}
          text={item.title}
          checked={selectedCheckboxes.includes(item.id)}
          onClick={() => handleCheckboxes(item.id)}
        />
      ))}
    </div>
  );
};

export const DateTimePickerItem = ({ title, value, onChange, maxDate, minDate }) => {
  return (
    <div className="filter-calendar-container">
      <span className="date-filter-dropdown-label">{title}</span>
      <DateTimePicker
        className="filter-calendar"
        onChange={onChange}
        value={value}
        maxDate={maxDate}
        minDate={minDate}
        locale="lv"
        disableClock
      />
    </div>
  );
};

const DateTime = ({ setFilter }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [startDate, setStartDate] = useState(
    searchParams.get("field_changed_date_value_from") &&
      moment(searchParams.get("field_changed_date_value_from"), "YYYY-MM-DD HH:mm:ss", true).isValid()
      ? moment(searchParams.get("field_changed_date_value_from")).toDate()
      : null
  );

  const [endDate, setEndDate] = useState(
    searchParams.get("field_changed_date_value_to") &&
      moment(searchParams.get("field_changed_date_value_to"), "YYYY-MM-DD HH:mm:ss", true).isValid()
      ? moment(searchParams.get("field_changed_date_value_to")).toDate()
      : null
  );

  const handleSetStartDate = (val) => {
    setSearchParams((current) => {
      return {
        ...current,
        field_changed_date_value_from:
          val && moment(val).isValid() ? moment(val).format("YYYY-MM-DD HH:mm:ss") : undefined,
      };
    });
    setStartDate(val);
    setFilter({
      field_changed_date_value_from:
        val && moment(val).isValid() ? moment(val).format("YYYY-MM-DD HH:mm:ss") : undefined,
    });
  };
  const handleSetEndDate = (val) => {
    setSearchParams((current) => {
      return {
        ...current,
        field_changed_date_value_to:
          val && moment(val).isValid() ? moment(val).format("YYYY-MM-DD HH:mm:ss") : undefined,
      };
    });
    setEndDate(val);
    setFilter({
      field_changed_date_value_to:
        val && moment(val).isValid() ? moment(val).format("YYYY-MM-DD HH:mm:ss") : undefined,
    });
  };

  useEffect(() => {
    const newStartDate = searchParams.get("field_changed_date_value_from");
    const newEndDate = searchParams.get("field_changed_date_value_to");

    setStartDate(
      newStartDate && moment(newStartDate, "YYYY-MM-DD HH:mm:ss", true).isValid()
        ? moment(newStartDate).toDate()
        : null
    );
    setEndDate(
      newEndDate && moment(newEndDate, "YYYY-MM-DD HH:mm:ss", true).isValid()
        ? moment(newEndDate).toDate()
        : null
    );

    setFilter({
      field_changed_date_value_from:
        newStartDate && moment(newStartDate, "YYYY-MM-DD HH:mm:ss", true).isValid()
          ? moment(newStartDate).format("YYYY-MM-DD HH:mm:ss")
          : undefined,
      field_changed_date_value_to:
        newEndDate && moment(newEndDate, "YYYY-MM-DD HH:mm:ss", true).isValid()
          ? moment(newEndDate).format("YYYY-MM-DD HH:mm:ss")
          : undefined,
    });
  }, [searchParams]);

  const maxDate = useMemo(() => moment().endOf("day").toDate(), []);

  return (
    <div className="catalog-main-filter-group">
      <div className="">{t("lastUpdated")}:</div>
      <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
        {/* Date from */}
        <DateTimePickerItem
          title={`${t("after")}: `}
          onChange={(val) => {
            handleSetStartDate(val);
            setFilter({
              field_changed_date_value_from: val ? moment(val).format("YYYY-MM-DD HH:mm:ss") : undefined,
            });
          }}
          value={startDate}
          maxDate={endDate ?? maxDate}
        />

        {/* Date to */}
        <DateTimePickerItem
          title={`${t("before")}: `}
          onChange={(val) => {
            handleSetEndDate(val);
            setFilter({
              field_changed_date_value_to: val ? moment(val).format("YYYY-MM-DD HH:mm:ss") : undefined,
            });
          }}
          value={endDate}
          minDate={startDate}
          maxDate={maxDate}
        />
      </div>
    </div>
  );
};

const AdvancedSearch = ({ setFilter }) => {
  const [open, setOpen] = useState(false);
  const rotationStyle = useMemo(() => {
    return open ? { transform: "rotate(180deg)" } : {};
  }, [open]);

  return (
    <div className="catalog-main-filter-group">
      <div
        className="catalog-main-filter-link link focus-red"
        tabIndex={0}
        onClick={() => setOpen((current) => !current)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setOpen((current) => !current);
          }
        }}
      >
        <span className="catalog-main-filter-link-text">
          <Trans i18nKey="generalAdvancedSearch" />
        </span>
        <div className="test" style={rotationStyle} />
      </div>
      <div
        style={{
          display: open ? "block" : "none",
          transition: "display 0.5s",
          padding: "1rem",
        }}
      >
        <DateTime setFilter={setFilter} />
      </div>
    </div>
  );
};

const TotalItems = ({ totalItems, filters, resetFilters }) => {
  const canReset = useMemo(() => {
    for (const [key] of Array.from(filters)) {
      if (key !== "page" && key !== "sort_by") return true;
    }
    return false;
  }, [filters]);

  return (
    <div className="catalog-main-filter-group">
      <div className="catalog-main-filter-result">
        <span className="catalog-main-filter-result-text">
          <Trans i18nKey="generalFilteredDS" />
        </span>
        &nbsp;
        <span className="catalog-main-filter-result-number">{totalItems ?? 0}</span>
        &nbsp;
        <span className="catalog-main-filter-result-text">
          <Trans i18nKey="titleDataSets" />
        </span>
        {canReset && (
          <>
            &nbsp;
            <div
              className="catalog-main-filter-link link focus-red"
              tabIndex={0}
              onClick={resetFilters}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  resetFilters();
                }
              }}
            >
              <span className="catalog-main-filter-link-text">{t("showAll")}</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export const Filters = ({ dropdowns, filters, setFilter, resetFilters, totalItems }) => {
  return (
    <div className="catalog-main-filter">
      <div className="catalog-main-filter-title">
        <Trans i18nKey="generalFilters" />
      </div>

      <MapToggle setFilter={setFilter} />
      <Sort setFilter={setFilter} />
      <Dropdowns dropdowns={dropdowns} filters={filters} setFilter={setFilter} />
      <CheckBoxes setFilter={setFilter} />
      <DateTime setFilter={setFilter} />
      {/* <AdvancedSearch setFilter={setFilter} /> */}
      <TotalItems totalItems={totalItems} filters={filters} resetFilters={resetFilters} />
    </div>
  );
};
