import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { StateContext } from "../../context";
import SubNavbarData from "./subnavbar_data";
import { getCms } from "../../classes/cmsApi";
import Loader from "../loader";
import { useQuery } from "@tanstack/react-query";

const nestChildren = (data) => {
  let map = {};
  let result = [];

  // map the nodes of the array by their UUIDs
  data.forEach((item) => {
    map[item.uuid] = Object.assign({}, item);
  });

  // add children to their parents
  data.forEach((item) => {
    let parentUuid = item.parent ? item.parent.split(":")[1] : null;
    if (parentUuid && map[parentUuid]) {
      let parent = map[parentUuid];
      if (!parent.children) parent.children = [];
      parent.children.push(map[item.uuid]);
    } else {
      result.push(map[item.uuid]);
    }
  });

  return result;
};

const NavbarData = () => {
  const { language, setNavBarOpened, setFontSize } = useContext(StateContext);
  const navigate = useNavigate();
  const { data, isLoading, isError, error } = useQuery(
    ["navbar", language],
    async () => {
      return nestChildren(await getCms("get/main_menu", language));
    },
    {
      refetchOnWindowFocus: false,
      staleTime: 10000, // cache for 10 minutes
    }
  );

  if (isLoading) return <Loader />;
  if (isError) {
    console.error(error);
    return null;
  }
  if (!data?.length) return null;

  return (
    <>
      <button
        className="navbar-menus-tab-title focus-red"
        id="home-icon-button"
        onClick={() => {
          navigate(`/${language}`);
          setNavBarOpened(false);
        }}
      >
        <img alt="home" src="/assets/img/home.svg" id="home-icon" />
      </button>
      {data.map((item, key) => {
        const linkTitleWithLanguege = item.link__title + `?lang=${language}`;
        return (
          <div className="navbar-menus-tab" key={key}
            onBlur={(a) => {
              if (!a.relatedTarget) {
                let items = document.getElementsByClassName("navbar-menus-tab-imgs");
                let item = items[0];
                item.classList.remove("visible");

                let items2 = document.getElementsByClassName("navbar-menus-tab-content");
                let item2 = items2[0];
                item2.classList.remove("visible");
              } else {
                if (!a.relatedTarget.classList.contains("navbar-menus-tab-imgs-item")) {
                  let items = document.getElementsByClassName("navbar-menus-tab-imgs");
                  let item = items[0];
                  item.classList.remove("visible");
                }
              }

            }}
          >
            <button
              className="navbar-menus-tab-title focus-red"
              onClick={() => {
                if (!(linkTitleWithLanguege === "/lv" || linkTitleWithLanguege === "/en")) {
                  if (
                    linkTitleWithLanguege.startsWith("http://") ||
                    linkTitleWithLanguege.startsWith("https://")
                  )
                    window.open(linkTitleWithLanguege, "_blank");
                  else navigate(linkTitleWithLanguege);
                }
              }}
              style={{ textDecoration: "none" }}
              onFocus={(a) => {
                let items = document.getElementsByClassName("navbar-menus-tab-imgs");
                let item = items[0];

                let items2 = document.getElementsByClassName("navbar-menus-tab-content");
                let item2 = items2[0];
                if (!item.classList.contains("visible") && key === 0) {
                  item.classList.add("visible");
                }

                if (!item2.classList.contains("visible") && key === 2) {
                  item2.classList.add("visible");
                }
                if (key !== 0 && key !== 2) {
                  item.classList.remove("visible");
                  item2.classList.remove("visible");
                }
              }}
              onMouseLeave={(event) => {
                if (event.relatedTarget && event.relatedTarget instanceof Node && document.contains(event.relatedTarget)) {
                  if (event.currentTarget.contains(event.relatedTarget)) {
                    return;
                  }
                }
                let items = document.getElementsByClassName("navbar-menus-tab-imgs");
                let item = items[0];
                item.classList.remove("visible");

                let items2 = document.getElementsByClassName("navbar-menus-tab-content");
                let item2 = items2[0];
                item2.classList.remove("visible");
              }}
            >
              {item.title}
              {item.children && <div className="test" />}
              {linkTitleWithLanguege.startsWith("http://") || linkTitleWithLanguege.startsWith("https://") ? (
                <img
                  className="map-link-icon"
                  alt="alt"
                  style={{ marginLeft: 10, width: 13 }}
                  src="/assets/img/link.svg"
                />
              ) : (
                <></>
              )}
            </button>
            <div className={item.field_submenu_class_name}>
              <SubNavbarData
                children={item.children}
                setNavBarOpened={setNavBarOpened}
                setFontSize={setFontSize}
              />
            </div>
          </div>
        );
      })}
    </>
  );
};

export default NavbarData;
