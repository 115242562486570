import { Dropdown, Input } from "..";
import { useTranslation } from "react-i18next";

const BasicInfo = ({
  defval,
  getValues,
  register,
  getFieldState,
  setValue,
  errors,
  isNew,
  languages,
  resourceTypes,
  serviceType,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <div className="input-group">
        <Input
          title={t("datasetAddLatvianLanguage")}
          name={"dataset_name_lv"}
          placeholder={t("requiredField")}
          defval={defval?.dataset_name_lv}
          register={register}
          errors={errors}
          rules={{
            required: { message: "fieldIsRequired", value: true },
          }}
          maxLength={250}
          setValue={setValue}
          getValues={getValues}
          getFieldState={getFieldState}
        />
        <Input
          title={t("datasetAddEnglishLanguage")}
          name={"dataset_name"}
          placeholder={t("requiredField")}
          defval={defval?.dataset_name}
          register={register}
          errors={errors}
          rules={{
            required: { message: "fieldIsRequired", value: true },
          }}
          maxLength={250}
          setValue={setValue}
          getValues={getValues}
          getFieldState={getFieldState}
        />
        <Input
          title={t("datasetAddDate")}
          name={"metadata_date"}
          placeholder={t("requiredField")}
          defval={defval?.metadata_date}
          register={register}
          errors={errors}
          setValue={setValue}
          getValues={getValues}
          getFieldState={getFieldState}
          disabled={true}
        />
      </div>

      {/* Insert Datu kopas attēls here */}

      <div className="input-group">
        <Input
          name={"dataset_description_lv"}
          placeholder={t("requiredField")}
          title={t("datasetAddDescriptionLatvian")}
          defval={defval?.dataset_description_lv}
          register={register}
          errors={errors}
          rules={{
            required: { message: "fieldIsRequired", value: true },
          }}
          setValue={setValue}
          getValues={getValues}
          getFieldState={getFieldState}
          big={true}
        />
        <Input
          name={"dataset_description"}
          title={t("datasetAddDescriptionEnglish")}
          placeholder={t("requiredField")}
          defval={defval?.dataset_description}
          register={register}
          errors={errors}
          rules={{
            required: { message: "fieldIsRequired", value: true },
          }}
          setValue={setValue}
          getValues={getValues}
          getFieldState={getFieldState}
          big={true}
        />
      </div>

      {/* Hidden for now */}
      {/* <div className="input-group">
        <Dropdown
          fieldName="service_type"
          title={t("datasetServiceType")}
          placeholder={t("dropdownPick")}
          defval={defval?.service_type}
          register={register}
          errors={errors}
          options={serviceType}
          setValue={setValue}
          getValues={getValues}
          getFieldState={getFieldState}
        />
      </div> */}
    </>
  );
};

export default BasicInfo;
