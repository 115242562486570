import { useContext } from "react";
import { StateContext } from "../context";
import { Trans } from "react-i18next";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { usr } from "../classes/user";
import propTypes from "prop-types";
import { t } from "i18next";

const subPageToOpen = (currentPage) => {
  currentPage = currentPage.replace(/\/lv|\/en/g, "");

  switch (currentPage) {
    case "/klusti":
      return "platformIntro";
    case "/about":
      return "platformOverview";
    case "/kontakti":
      return "platformContacts";
    case "/catalog":
      return usr.can("API dokumentācija (datu devējam)") ? "publishData" : "subAndManageData";
    case "/abonetaskopas":
      return "apiUseCases";
    case "/buj":
      return "reportAProblem";
    case "/apliecinajumi":
      return "certificates";
    default:
      return undefined;
  }
};

const helpPagePreOpen = (currentPage) => {
  const subPage = subPageToOpen(currentPage);
  if (subPage) {
    return `?searchWord=${t(subPage)}`;
  }
  if (!usr?.sub) return "";
  return `?searchWord=${
    usr?.can("API dokumentācija (datu devējam)") ? t("publishData") : t("subAndManageData")
  }`;
};

const BannerPath = ({
  path,
  pathUrl,
  renderArrowBefore = true,
  onClick = undefined,
  isLastAndUnclickable = false,
}) => {

  const { language } = useContext(StateContext);

  const navigate = useNavigate();
  if (!path) return null;

  const handleClick = () => {
    // prioritize the navigateFunction if provided
    if (onClick) {
      onClick();
    } else if (pathUrl) {
      // if path url is home page, navigate to home page with language so the link is not instantly changed to /lv or /en from /
      if (pathUrl === "/") {
        navigate(`/${language}`);
      } else {
        // finally use the default navigate function
        navigate(pathUrl);
      }
    }
  };

  const content = (
    <div 
      style={{ textDecoration: "none" }} 
      className={"focus-red"} 
      onClick={handleClick} 
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          handleClick();
        }
      }}
      tabIndex={0}
    >
      {path.toUpperCase()}
    </div>
  );

  return (
    <>
      {renderArrowBefore && (
        <img alt="alt" className="catalog-header-left-directory-icon" src="/assets/img/arrow.png" />
      )}
      <div className={`catalog-header-left-directory-step ${isLastAndUnclickable ? "last" : ""}`}>
        {content}
      </div>
    </>
  );
};
BannerPath.propTypes = {
  path: propTypes.string,
  pathUrl: propTypes.string,
  renderArrowBefore: propTypes.bool,
  onClick: propTypes.func,
  isLastAndUnclickable: propTypes.bool,
};

const Banner = ({ bannerData, children, shouldCenterImg, id }) => {
  const navigate = useNavigate();
  const { language } = useContext(StateContext);
  const location = useLocation();
  const currentPage = location.pathname; // This will provide thee the current URL path

  let shouldSkipEasyRead = ["/apliecinajumi", "/vieglilasit", "/manaskopas/list"].some((path) =>
    currentPage.includes(path)
  );

  let shouldSkipHelpAndEasyToRead = [
    "/passwordcreate",
    "/passwordreset",
    "/dashboard",
    "/profile",
    "/abonetaskopas",
    "/messages",
    "/manaskopas/list",
  ].includes(currentPage);

  let shouldSkipHelp = ["/palidziba", "/abonetaskopas"].includes(currentPage);

  const getBannerPaths = (bannerData) => {
    const paths = [];
    let index = 1;
    while (bannerData[`path${index}`]) {
      paths.push({
        path: bannerData[`path${index}`],
        pathUrl: bannerData[`path${index}Url`],
        navigateFunction: bannerData[`path${index}NavigateFunction`],
        isLastAndUnclickable:
          !bannerData[`path${index + 1}`] && // Check if there is no next path
          !bannerData[`path${index}Url`] && // Check if the current last path has no URL
          typeof bannerData[`path${index}NavigateFunction`] !== "function", // Check if the current last path has no navigate function

      });
      index++;
    }
    return paths;
  };

  return (
    <div className="catalog-header" id={id}>
      <div className="catalog-header-left">
        <div
          className={
            shouldCenterImg
              ? "catalog-header-left-topic center-banner-title-img"
              : "catalog-header-left-topic"
          }
          title={bannerData.title}
        >
          {bannerData.title}
          {bannerData.img && <img alt="alt" className={bannerData.img.className} src={bannerData.img.href} />}
        </div>
        <div className="catalog-header-left-directory">
          {getBannerPaths(bannerData).map((pathData, index) => (
            <BannerPath
              key={index}
              path={pathData.path}
              pathUrl={pathData.pathUrl}
              renderArrowBefore={index === 0 ? false : true}
              onClick={pathData.navigateFunction ? () => pathData.navigateFunction() : undefined}
              isLastAndUnclickable={pathData.isLastAndUnclickable}
            />
          ))}
        </div>
      </div>
      {children}
      {!shouldSkipHelpAndEasyToRead && (
        <div className="catalog-header-right">
          {!shouldSkipHelp && (
            <div
              className="catalog-header-right-text1 focus-red"
              onClick={() => navigate(`/palidziba${helpPagePreOpen(currentPage)}`)}
              tabIndex={0}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  navigate(`/palidziba${helpPagePreOpen(currentPage)}`);
                }
              }}
            >
              <img className="catalog-header-right-icon" alt="alt" src="/assets/img/book.svg" />
              <Trans i18nKey="pathingHelp" />
            </div>
          )}
          {!shouldSkipEasyRead && (
            <>
              <div className="catalog-header-right-flash">|</div>
              <div
                className="catalog-header-right-text2 focus-red"
                tabIndex={0}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    navigate(`/${language}/vieglilasit`);
                  }
                }}
              >
                <div
                  onClick={() => {
                    navigate(`/${language}/vieglilasit`);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  <Trans i18nKey="pathingEasyRead" />
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default Banner;
