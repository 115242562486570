import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // we init with resources
        resources: {
            en: {
                translations: {
                    january: "January",
                    february: "February",
                    march: "March",
                    april: "April",
                    may: "May",
                    june: "June",
                    july: "July",
                    august: "August",
                    september: "September",
                    october: "October",
                    november: "November",
                    december: "December",
                    sunShort: "Sun",
                    monShort: "Mon",
                    tueShort: "Tue",
                    wedShort: "Wed",
                    thuShort: "Thu",
                    friShort: "Fri",
                    satShort: "Sat",
                    sunMin: "Su",
                    monMin: "Mo",
                    tueMin: "Tu",
                    wedMin: "We",
                    thuMin: "Th",
                    friMin: "Fr",
                    satMin: "Sa",
                    sunday: "Sunday",
                    monday: "Monday",
                    tuesday: "Tuesday",
                    wednesday: "Wednesday",
                    thursday: "Thursday",
                    friday: "Friday",
                    saturday: "Saturday",
                    sessionTimeOver: "The browser session has expired.",
                    closeTheBrowserBegin: "Please close the browser or tab, or ", //  
                    closetheBrowserEndLink: "authenticate again!",
                    datasetNid: "Dataset id:",
                    errorOccured: "An error occured",
                    errorOccuredMessage: "An error occured while processing your request. Please try again later.",
                    tryAgain: "Try again",
                    weDontHaveThisPage: "We don't have this page",
                    descFieldMandatory: "Content field is mandatory!",
                    subscribeToDS: "Subscribe to the dataset",
                    system: "System",
                    system_admin: "Admin",
                    realTimeDataStart: "Devices giving realtime",
                    realTimeDataEnd: "traffic data",
                    dataInstancesStart: "Data instances", 
                    dataInstancesEnd: "stored in the platform",
                    dataSetsCountStart: "Data sets with API",
                    dataSetsCountEnd: "",
                    help: "Help",
                    registrationJuridicalLastName: "Last name",
                    termsOfUse: "Terms of use",
                    about: "About the Project",
                    cancel: "Cancel",
                    datasetCoordinateSystem: "Coordinate system:",
                    datasetGeoreferencing: "Geo-referencing method",
                    datasetDataVersion: "Data version",
                    datasetNationalAssessmentDate: "National Body assessment date",
                    datasetDatexPublicationType: "Datex Publication type",
                    datasetCardValueLV0Latvija: "LV0 - Latvia",
                    datasetCardValueNekadvienreizējsnodrošinājums: "Never (one-time provision)",
                    datasetCardValuefile: "File",
                    datasetCardValueapi: "Data change stream",
                    datasetCardValuebasic: "Basic",
                    datasetCardValueenhanced: "Standard",
                    datasetCardValueadvanced: "Enhanced",
                    datasetCardValueroyaltyfree: "Royalty free",
                    datasetCardValueOther: "Other",
                    datasetCardValueotherFieldUpdateFrequency: "Other Change Frequencies",
                    datasetCardValueotherFieldDataFormatModel: "Other Data Format Models",
                    datasetCardValueotherFieldDataFormatSyntax: "Other Data Format Syntaxes",
                    datasetCardValueotherFieldLicenceConditions: "Other Licensing Conditions",
                    datasetCardValueotherFieldLegalFramework: "Other Legal Frameworks",
                    datasetCardValueotherFieldAccessInterface: "Other Access Interfaces",
                    datasetCardValueotherFieldServiceType: "Other Service Types",
                    datasetCardValueothersFieldTransportModeCoverage: "Other Transport Modes",
                    datasetCardValueLocationsearch: "Location search",
                    datasetCardValueInformationservice: "Information service",
                    datasetCardValueTripplans: "Trip plans",
                    datasetCardValueTripplansauxiliaryinformationavailabilitycheck: "Trip plans, auxiliary information, availability check",
                    datasetCardValueTripplancomputationscheduledmodestransport: "Trip plan computation - scheduled modes transport",
                    datasetCardValueDynamicPassingtimestripplansandauxiliaryinformation: "Dynamic Passing times, trip plans and auxiliary information",
                    datasetCardValueDynamicInformationservice: "Dynamic Information service",
                    datasetCardValueDynamicavailabilitycheck: "Dynamic availability check",
                    datasetCardValueotherFieldCoordinateSystem: "Other Coordinate Systems",
                    datasetCardValueotherFieldGeoreferencingMethod: "Other Georeferencing Methods",
                    datasetCardValueotherFieldTransportModeCoverage: "Other Transport Modes",
                    datasetCardValueotherFieldDatexPublicationType: "Other Datex Publication Types",
                    datasetCardValueNepārtraukti: "Continuous",
                    datasetCardValueJā: "Yes",
                    datasetCardValueNē: "No",
                    datasetCardValueDatuizmaiņuplūsma: "Data change stream",
                    datasetCardValueDatnes: "Data set",
                    // datasetCardValue

                    datasetCardValueAutomaģistrāles: "Motorways",
                    datasetCardValueŠtataceļivaifederālieceļi: "State and federal roads",
                    datasetCardValueValstsceļivaifederālieceļi: "State and federal roads",
                    datasetCardValueReģionālieceļi: "Regional roads",
                    datasetCardValuePilsētasunvietējieceļi: "City and local roads",
                    datasetCardValueDzelzceļatīkls: "Railway network",
                    datasetCardValueMetrotramvajuvaivieglādzelzceļatīkls: "Metro, tram, or light rail network",
                    datasetCardValueCitssabiedriskātransportatīkls: "Other public transport network",
                    datasetCardValueŪdensceļi: "Waterways",
                    datasetCardValueGaisatīkls: "Air network",
                    datasetCardValueTERNtīkls: "TERN network",
                    datasetCardValueTENtīkls: "TEN network",
                    datasetCardValueCiti: "Others",
                    datasetCardValueNeregulāri: "On occurrence/Irregularly",
                    datasetCardValueReizi1minūtē: "Up to 1 minute",
                    datasetCardValueReizi5minūtēs: "Up to 5 minutes",
                    datasetCardValueReizi10minūtēs: "Up to 10 minutes",
                    datasetCardValueReizi15minūtēs: "Up to 15 minutes",
                    datasetCardValueReizi30minūtēs: "Up to 30 minutes",
                    datasetCardValueReizi1stundā: "Up to 1 hour",
                    datasetCardValueReizi2stundās: "Up to 2 hours",
                    datasetCardValueReizi3stundās: "Up to 3 hours",
                    datasetCardValueReizi12stundās: "Up to 12 hours",
                    datasetCardValueReizi24stundās: "Up to 24 hours",
                    datasetCardValueReizinedēļā: "Up to weekly",
                    datasetCardValueReizimēnesī: "Up to monthly",
                    datasetCardValueReizi3mēnešos: "Up to every 3 months",
                    datasetCardValueReizi6mēnešos: "Up to every 6 months",
                    datasetCardValueReizigadā: "Up to yearly",
                    datasetCardValueRetāknekāreizigadā: "Less frequent than yearly",
                    datasetCardValueNezināms: "Unknown",
                    datasetCardValueCits: "Other",
                    datasetCardValueAir: "Air",
                    datasetCardValueBicycle: "Bicycle",
                    datasetCardValueBikeHire: "Bike Hire",
                    datasetCardValueBikeSharing: "Bike Sharing",
                    datasetCardValueBus: "Bus",
                    datasetCardValueCar: "Car",
                    datasetCardValueLongdistancecoach: "Long-distance coach",
                    datasetCardValuePrivatecar: "Private car",
                    datasetCardValueCarHire: "Car Hire",
                    datasetCardValueCarPooling: "Car Pooling",
                    datasetCardValueCarSharing: "Car Sharing",
                    datasetCardValueLongdistancerail: "Long-distance rail",
                    datasetCardValueMaritimeincludingferry: "Maritime including ferry",
                    datasetCardValueMetroSubwaytrain: "Metro / Subway train",
                    datasetCardValueMotorcycle: "Motorcycle",
                    datasetCardValuePedestrian: "Pedestrian",
                    datasetCardValueRegionalandlocalrail: "Regional and local rail",
                    datasetCardValueRidePooling: "Ride Pooling",
                    datasetCardValueShuttlebus: "Shuttle bus",
                    datasetCardValueShuttleferry: "Shuttle ferry",
                    datasetCardValueTaxi: "Taxi",
                    datasetCardValueTramLightrail: "Tram / Light rail",
                    datasetCardValueTruck: "Truck",
                    datasetCardValueTrolleybus: "Trolley bus",
                    datasetCardValueNotapplicable: "Not applicable",
                    datasetCardValueOthercontractualagreement: "Other contractual agreement",
                    datasetCardValueCreativeCommonsCCZero: "Creative Commons CC Zero",
                    datasetCardValueCreativeCommonsCCBY: "Creative Commons CC BY",
                    datasetCardValueCreativeCommonsCCBYSA: "Creative Commons CC BY-SA",
                    datasetCardValueCreativeCommonsCCBYND: "Creative Commons CC BY-ND",
                    datasetCardValueCreativeCommonsCCBYNC: "Creative Commons CC BY-NC",
                    datasetCardValueCreativeCommonsCCNCSA: "Creative Commons CC NC-SA",
                    datasetCardValueCreativeCommonsCCBYNCND: "Creative Commons CC BY-NC-ND",
                    datasetCardValueSoftwarelicenseofApacheSoftwareFoundation: "Software license of Apache Software Foundation",
                    datasetCardValueGNUFreeDocumentationLicense: "GNU Free Documentation License",
                    datasetCardValueMozillaPublicLicense: "Mozilla Public License",
                    datasetCardValueOpenDataCommonsAttributionLicenseODCBY10: "Open Data Commons Attribution License (ODC-BY 1.0)",
                    datasetCardValueOpenDataCommonsOpenDatabaseLicenseODbL: "Open Data Commons Open Database License (ODbL)",
                    datasetCardValueOpenDataCommonsPublicDomainDedicationsandLicenseODCPDDL: "Open Data Commons Public Domain Dedication and License (PDDL)",
                    datasetCardValuePublicDomainMark10PDM: "Public Domain Mark 1.0 (PDM)",
                    datasetCardValueNotrelevant: "Not relevant",


                    datasetCardValueSākotnējaisieraksts: "Initial data entry",
                    datasetCardValuefirst_record: "Initial data entry",
                        
                    datasetAddAccessProtocol: "Access Interface",
                    datasetAddUpdateFreq: "Update frequency:",
                    dataseteAddStandardLicence: "Contract or licence conditions:",
                    datasetAddCategory: "Data set category:",
                    noOptions: "No options",
                    datasetAddSubCategory: "Data set category detailed:",
                    datasetAddSubCategories: "Data set categories detailed",
                    datasetAddDataOwner: "Data owner:",
                    datasetAddDataGiver: "Data publisher:",
                    datasetAddDataOwnerEmail: "Data owner e-mail:",
                    validUntil: "Valid to:",
                    datasetAddValidToTooltip: "The date until which the data set is valid",
                    basicInfo: "Basic information",
                    additionalInfo: "Additional information",
                    datasetAddLatvianLanguage: "Data set name latvian:",
                    datasetAddEnglishLanguage: "Data set name english:",
                    datasetAddDate: "Metadata Date:",
                    datasetMetadataLanguage: "Data set languages",
                    delete: "Delete",
                    deleteFile: "Delete file",
                    restoreFile: "Edit file",
                    unSubscribe: "Unsubscribe",
                    unSubscribeLong: "Unsubscribe",
                    dropdownMyProfile: "My profile",
                    dropdownDocumentation: "Documentation",
                    dropdownInbox: "Inbox",
                    noMessagesNo: "No",
                    noMessagesMessages: "new messages",
                    noMessagesMessagesOne: "new message",
                    noMessagesMessagesMany: "new messages",
                    fontSize: "Font size",
                    langLat: "Latviski",
                    langEn: "English",
                    settings: "Settings",
                    contrast: "Contrast",
                    authorize: "Sign In",
                    logout: "Logout",
                    search: "Search",
                    searchPlaceHolder: "Your search criteria...",
                    foundStart: "Find - found",
                    foundStartOne: "",
                    foundStartMultiple: "",
                    foundEnd: " result",
                    foundEndOne: "",
                    foundEndMultiple: "s",
                    loadMore: "Load more",
                    allPlatform: "Entire platform",
                    inDataCatalog: "Data catalogue",
                    dataCatalog: "Data catalogue",
                    interactiveMap: "Interactive map",
                    contacts: "Contacts",
                    copyRight: "2023 © Copyright SLLC \"Latvian State Roads\", National Access Point (NAP). All rights reserved.",
                    titleRegister: "Sign in",
                    titleCreateProfile: "Create a profile",
                    titleDataSets: "Data Sets",
                    titleDataProvidedBy: "Data Providers",
                    menuGreeting: "Hi",
                    menuProfileFeatures: "Profile features",
                    menuProfileFeaturesCaps: "PROFILE FEATURES",
                    pathingHelp: "Help",
                    pathingEasyRead: "Easy to read",
                    pathingUserType: "Profile type: ",
                    workspaceInbox: "Inbox",
                    workspaceMyInbox: "My inbox",
                    viewInbox: "View incoming messages and system notifications",
                    workspacePublishedData: "Published data",
                    workspaceAddDataSets: "Add new datasets and manage added datasets",
                    workspaceDocumentation: "Documentation",
                    workspaceDocumentationInfo: "Access the API specification and guidelines for managing datasets",
                    workspaceDeclarations: "My declarations",
                    workspaceDeclarationsShort: "Declarations",
                    workspaceUploadDeclarations: "Upload data declarations and manage their validity",
                    workspaceMyProfile: "My profile",
                    workspaceProfileDescription: "Manage user profile settings and change your password",

                    workspaceCharts: "Reports",
                    workspaceChartsDescription: "Reports for more detailed data visualization and viewing",
                    workspaceLogout: "Log out",
                    workspaceLogoutDescription: "End work and log out of the platform",
                    workspaceSubscribedData: "Subscribed data",
                    workspaceSubscribedDataCaps: "SUBSCRIBED DATA",
                    failedToSaveData: "Failed to save data set",
                    workspaceStart: "HOME",
                    searchResults: "Search results",
                    userRegistration: "User registration",
                    datasetDataGiver: "Data publisher:",
                    datasetDataGiverEmail: "Data publisher email:",
                    datasetDataGiverPlain: "Data publisher",
                    datasetUpdateFreq: "Updates:",
                    datasetDeletable: "Dataset will be deleted by: ",
                    datasetDeletableShort: "Deleted by: ",
                    datasetPublished: "Published:",
                    datasetScore: "Rating:",
                    datasetLastChanges: "Last changes:",
                    datasetChangeHistory: "History",
                    datasetSubscribe: "Subscribe",
                    datasetToCatalog: "Catalogue",
                    datasetDescription: "Description of the data set",
                    datasetViewMap: "View on map",
                    datasetModel: "Data standard: ",
                    datasetLegalFramework: "Legal frameworks relevant to data set or service: ",
                    datasetDistibutionMode: "Data distribution mode: ",
                    datasetUniqueIdentifier: "Data set UID: ",
                    datasetLanguage: "Language: ",
                    languageEnglish: "English",
                    languageeng: "English",
                    languageLatvian: "Latvian",
                    languagelav: "Latvian",
                    datasetAvailableFormats: "Available data formats:",
                    datasetDatexPublicationClass: "Datex publication class",
                    datasetAddDatexPublicationClass: "Datex publication class:",
                    datasetOverall: "In general about data set",
                    datasetReportProblem: "Report problem",
                    generalFilters: "Filters",
                    generalSorters: "Sorters",
                    generalFiltersAll: "All",
                    generalFiltersWithMap: "With map",
                    generalAllCategories: "All categories",
                    generalAllSubCategories: "All subcategories",
                    generalAllDataFormats: "All data formats",
                    generalAllDataModels: "All data models",
                    generalAllEventTypes: "All event types",
                    generalAllDataTypes: "All data types",
                    generalAllLanguages: "All languages",
                    generalAllUsers: "All data givers",
                    showAll: "Show all",
                    allMapLayers: "All map layers",
                    currentTrafficEvents: "Current traffic events",
                    drivingConditionsWarnings: "Driving conditions warnings",
                    plannedEvents: "Planned events",
                    camerasAndVehicles: "Cameras and vehicles",
                    chargingAndFillingPoints: "Charging and filling points",
                    parkingLots: "Parking lots",
                    roadNetworkData: "Road network data",
                    weightAndDimensionsRestrictions: "Weight and dimensions restrictions",
                    generalSortByTitle: "Sort by title",
                    generalSortByDateCreated: "Sort by date created",
                    generalSortByDateUpdated: "Sort by last updated",
                    generalSortByUser: "Sort by data provider",
                    generalOnlyApi: "Only API",
                    generalRecents: "Recently updated (12 months)",
                    generalOnlyMap: "Only with map",
                    generalAdvancedSearch: "Advanced search",
                    generalFilteredDS: "Showing:",
                    generalLoadMoreResults: "Show more",
                    datasetAddPublicationType: "Publication type:",
                    datasetAddToAddInfo: "To start adding a dataset, choose the type of data to publish",
                    datasetAddData: "Data",
                    datasetAddInputData: "Fill in the required data and add dataset files",
                    datasetAddInfoAboutDataset: "Information about dataset",
                    datasetAddChangeHistory: "Change history",
                    datasetAddChooseDataType: "How do you plan to add data?",
                    datasetAddByAddingFiles: "By adding files",
                    datasetAddByAPI: "Data change stream (MQTT)",
                    datasetAddCannotChange: "You will not be able to change this setting. If necessary, you will be able to copy the data of the data set or create a new data set.",
                    datasetAddSaveAndContinue: "Save and continue",
                    datasetAddInfoAboutDataGiver: "Information about the data provider and contacts",
                    datasetAddInfoAboutData: "Information about data",
                    datasetAddSave: "Save",
                    datasetAddMakeACopy: "Make copy",
                    datasetAddDontPublishAnymore: "Delete (unpublish)",
                    datasetAddPublicationUID: "Distribution UID: ",
                    datasetAddPublicationStatus: "Status: ",
                    datasetAddPublicationMode: "Mode: ",
                    datasetAddPublicationDSUID: "Data set UID:",
                    datasetAddRating: "Score:",
                    datasetAddRatingCount: "Scores Count:",
                    datasetAddImage: "Image of the dataset",
                    datasetAddDSLanguages: "Data set languages",
                    datasetAddDescriptionLatvian: "Data set description (Latvian):",
                    datasetAddDescriptionEnglish: "Data set description (English):",
                    fieldIsRequired: "Field is required. ",
                    invalidEmail: "The provided e-mail does not match the required format",
                    invalidPhone: "Please enter a valid phone number (e.g., +371 67000000).",
                    invalidRegNr: "The registration number of the legal entity must consist of digits!",
                    invalidRegNrLength: "The registration number of the legal entity must be 11 digits!",
                    invalidWebpage: "The provided website does not match the required format",
                    datasetAddDataGiverEmail: "Data publisher e-mail:",
                    datasetAddDataGiverWebsite: "Data publisher website:",
                    datasetOwner: "Data publisher:",
                    datasetAddMetadataOwner: "Metadata owner:",
                    datasetAddDeclaration: "Declaration:",
                    datasetAddAgreePublishDeclaration: "I agree to publish the declaration",
                    datasetAddDisagreePublishDeclaration: "I do not agree to publish the declaration",
                    datasetAddAPIKey: "API key:",
                    datasetCantSub: "Dataset with no files cannot be subcribed to!",
                    datasetAPIKey: "API key & Endpoint",
                    datasetAPIKeyHelp: "API key & Endpoint for receiving data",
                    datasetAddDownloadURL: "Snapshot URL",
                    datasetAddSnapshotURL: "Sandbox Snapshot URL:",
                    datasetAddSnapshotMQTTUrl: "Sandbox MQTT topic:",
                    datasetAddFiles: "Add more files",
                    datasetAddFile: "Add file",
                    datasetFileNotFound: "File not found",
                    fileDescriptionLatvian: "File description in Latvian",
                    fileDescriptionEnglish: "File description in English",
                    errorInFile: "Error in file with name",
                    pleaseFillAllFields: "Please fill in all fields",
                    cancelRestoreFile: "Cancel editing",
                    fileNameLatvian: "File name in Latvian",
                    fileNameEnglish: "File name in English",
                    datasetErrorNoFileDescriptionLatvian: "File description in Latvian is required",
                    datasetErrorNoFileDescriptionEnglish: "File description in English is required",
                    datasetErrorNoFile: "File is required",
                    datasetErrorCheckFile: "File format is incorrect",
                    datasetErrorFilesSome: "Please check if all files match the allowed formats!",
                    datasetErrorFailedValidation: "File does not match the selected data model schema. Please check the file and try again!",
                    datasetErrorInvalidDate: "Invalid date format",
                    validUntilShouldBeAfterValidFrom: "Entered date is before valid from date",
                    topic: "Topic",
                    datasetAddMQTTTopic: "MQTT topic:",
                    datasetMQTTTopic: "MQTT topic",
                    datasetMQTTTopicHelp: "MQTT server topic for receiving data",
                    datasetAddMQTTTopicSource: "Source MQTT topic:",
                    datasetAddPublishedFrom: "Published from:",
                    datasetAddNextUpdate: "Next update date:",
                    datasetAddUpdated: "Version notes:",
                    datasetAddDataLegalFramework: "Legal framework:",
                    firstRecord: "First record",
                    lastUpdated: "Last updated",
                    before: "Before",
                    after: "After",
                    dataSample: "Sample",
                    dataSampleFull: "Data sample",
                    dataSetXML: "Datex II format dataset",
                    dataSetGeoJSON: "GeoJSON simplified data publication",
                    datasetAddContentLanguage: "Language of data content:",
                    datasetCardValueEnglish: "English",
                    datasetCardValueLatvian: "Latvian",
                    datasetAddGeographicalCoverage: "Geographical coverage (NUTS):",
                    datasetAddGeographicalCoverageCountry: "Geographical coverage (country):",
                    datasetAddGeographicalCoverageRegion: "Region:",
                    datasetAddGeographicalCoverageTooltip: "Select the region where the data set is valid. Field can be empty if the data set is valid for the entire country.",
                    country: "Country",
                    latvia: "Latvia",
                    datasetAddTransportNetworkCoverage: "Transport Network Coverage:",
                    datasetAddTransportModeCoverage: "Transportation modes coverage:",
                    datasetAddQualityDescription: "Quality description:",
                    datasetAddCommunicationMethod: "Communication Method:",
                    datasetAddResourceType: "Resource Type:",
                    datasetAddDataFormat: "Data format:",
                    datasetAddDataSyntax: "Data syntax:",
                    datasetAddDataSchema: "Data schema:",
                    datasetAddDataModel: "Data model:",
                    datasetAddDataModelSchema: "Data model schema:",
                    datasetAddDataModelSyntax: "Syntax:",
                    dropdownPick: "Pick",
                    requiredField: "Required field",
                    nonRequiredField: "Non-required field",
                    datasetAddDatexPublicationType: "Datex Publication type:",
                    datasetAddDataExample: "Data Sample",
                    datasetServiceType: "Service type:",
                    datasetFileSize: "Max file size: 60 MB",
                    datasetFileSizeError: "File size exceeds maximum allowed size (60 MB)",
                    dataFailedToLoad: "Data failed to load",
                    datasetUploadDataFile: "Upload data file",
                    datasetAddDataFile: "Data File",
                    addedAt: "Added at",
                    datasetDataSample: "Data sample:",
                    datasetUploadDataFiles: "Data files:",
                    datasetAddDataFiles: "Add data file",
                    registrationToStartRegistration: "To start registration process, please select a profile type",
                    registrationNewUserCreation: "Create new user",
                    registrationDataRecipient: "Data recipient",
                    registrationDataProvider: "Data provider",
                    registrationAccessNPPData: "Accesses the data hosted on the NPP platform, uses API and other features",
                    registrationWishToSubscribe: "Become a Data recipient",
                    registrationGivesDataToNPP: "Submits reliable data to the NAP platform, provides public access to his data",
                    registrationIWishToPublish: "Become a Data provider",
                    registrationWishToKnowMore: "MORE ABOUT PROFILE TYPES",
                    registrationDataReciverAndGiverAdvantages: "Data recipient and Data provider profile features",
                    registrationProfileInfo: "Profile information",
                    registrationProfileInfoForm: "Create new user",
                    registrationProfileInfoFormDD: "Profile information",
                    registrationProvideEmail: "Provide e-mail",
                    registrationProvideEmailForm: "Please provide your email to continue:",
                    registrationProvideEmailFormDD: "Please enter your contacts and other profile details:",
                    registrationEmailTooltipFormDD: "Profile e-mail",
                    registrationProvidePersonalInfo: "Provide legal information about the company and contacts",
                    registrationTOU: "Terms of Use",
                    registrationConfirm: "Confirm registration",
                    registrationWeWillSendLInk: "We sent a confirmation link to your email.",
                    registrationCreatePassword: "Create a password",
                    registrationProtectProfile: "Protect your profile with a secure and compliant password.",
                    registrationReadNPPRules: "Read and accept Terms of Use.",
                    registrationCreateNewUser: "Create new user",
                    registrationPleaseInputEmail: "Please provide your email to continue:",
                    registrationContinue: "Continue",
                    resolved: "Resolved",
                    rejected: "Rejected",
                    registrationUserExists: "User with the same e-mail address already registered! ",
                    registrationWrongEmail: "The e-mail was entered incorrectly!",
                    registrationReadEntire: "It is necessary to read the entire text of the rules and mark that you agree to the Terms of Use",
                    registrationJuridicalInfo: "Information about legal entity",
                    registrationJuridicalName: "Name",
                    registrationJuridicalRegNr: "Registration number",
                    registrationJuridicalAddress: "Address",
                    registrationJuridicalWebpage: "Website",
                    ContactpersonData: "Contact details",
                    ContactpersonFirstName: "First name",
                    ContactpersonLastName: "Last name",
                    email: "E-mail",
                    registrationEmail: "Contact e-mail",
                    organizationEmail: "Organization e-mail",
                    emailWillBePubliclyViewable: "The e-mail will be displayed in the public data catalogue and metadata",
                    ContactpersonPhoneNumber: "Phone number",
                    ContactpersonInfoAboutPublishedData: "Type of data you are planning to publish",
                    ContactpersonInfoAboutPublishedDataExample: "We publish data about...",
                    maxSize255: "Maximum 255 characters allowed.",
                    RegistrationIAmFamiliar: "I have read and agree to the Terms of Use of the NAP platform.",
                    registrationPassworCreation: "Create a password",
                    registrationPassworCreationSecure: "Secure and compliant password",
                    registrationPleasePassword: "Please create a password that consists of at least 9 symbols and includes: uppercase Latin letters, lowercase Latin letters, numbers and a special symbol.",
                    registrationAlmostSafe: "Almost secure, but something is still missing",
                    registrationSafe: "Password looks secure!",
                    registrationAccountCreated: "Profile has been created",
                    registrationAuthNow: "You can authorize now!",
                    registrationAuth: "Log in",
                    generalFix: "Edit",
                    generalContactperson: "Contact person",
                    generalRenewPassword: "Reset password",
                    generalChangePassword: "Change password",
                    generalDeleteProfile: "Delete account",
                    areYouSureYouWantToDeleteProfile: "Are you sure you want to delete your account?",
                    generalIAgreeThatTheAccountWillNoLongerBeRecoverableAndIWantToDeleteIt: "I agree that the account will no longer be recoverable and I want to delete it",
                    generalEnterDeletionReason: "Enter the reason for deletion",

                    popupMessageReport: "Report",
                    popupMessageOrAsk: "Report a bug or ask a question",
                    popupMessageQuestion: "Question",
                    popupMessageReportABug: "Report a bug",
                    popupMessageGenericMessage: "Send generic message",
                    popupMessageSend: "Send",
                    popupMessageRegisteredUser: "For existing users",
                    popupMessageEmail: "Email",
                    popupMessagePassword: "Password",
                    popupMessageLogIn: "Log in",
                    popupMessageHaventRegistered: "Not yet registered?",
                    popupMessageForgotPassword: "Forgot your password?",
                    popupMessageClose: "Close",
                    popupMessageSubsciptionSuccess: "Subscription successful!",
                    popupMessageSubsciptionFailed: "Subscription failed!",
                    connectionError: "Savienojuma kļūda, neizdevās ielādēt datus",
                    cannot_sub_with_no_files: "You cannot subscribe to a file dataset with no files!",
                    popupMessageModifyProfileName: "Edit profile name",
                    popupMessageModifyProfile: "Edit profile",
                    popupMessageWebsite: "Website",
                    popupMessageNewDeclaration: "New declaration",
                    popupMessageWeCreatedDeclaration: "We automatically created a declaration based on your profile information.",
                    popupMessageReadyToDownload: "Data download:",
                    popupMessageAddFile: "Attach the file",
                    popupMessageFileNotAdded: "File is not attached (.edoc, .pdf)",
                    popupMessageFileNotAddedPlain: "File is not attached",
                    popupMessagePleaseRateDataset: "Please rate the data sets quality!",
                    popupMessageThanksForRateDataset: "Thank you for submitting your review!",
                    veikModalFollow: "Monitor ",
                    veikModalSubscribedData: "your subscribed data",
                    veikModalAnd: " and ",
                    veikModalDocumentation: "documentation",
                    veikModalYourProfile: " in your profile.",  
                    universalPopupMaxFileRequestReachedTitle: "Exceeded maximum allowed downloads!",
                    universalPopupMaxFileRequestReachedDescription: "You have exceeded the maximu allowed amount of downloads for this specific file.",
                    popupMessageLowRating: "1 (low rating)",
                    popupMessageHighRating: "5 (high rating)",
                    popupMessageRate: "Rate data set",
                    popupMessagePleaseAnswer: "Please type a reply to the message...",
                    popupMessageChooseStatus: "Status",
                    popupMessageConfirmDeleteFile: "Are you sure you want to delete this file?",
                    popupMessageChangeSyntax: "Are you sure you want to change the dataset syntax? Previously added files may not be compatible with the new syntax.",
                    popupMessageChangeFormat: "Are you sure you want to change the dataset format? Changing the data format will delete all selected syntaxes AND previously added files.",
                    homeDataSets: "Data Sets",
                    headerDataServices: "Data",
                    headerDataCatalog: "Data catalogue",
                    workspaceViewInbox: "View incoming messages and system notifications",
                    workspaceHandleUserInfo: "Manage user profile settings and change your password",
                    Declarations: "Declarations",
                    datasetAddMetadataOwnerEmail: "Metadata owner e-mail:",
                    thanksYourReportNoted: "Thank you, your report has been noted!",
                    hasBeenSentToDataProvider: "Your report has been sent to the data provider!",
                    hasBeenSentToDataProviderAndAdmins: "Your report has been sent to the data provider and to the administrators of the NAP platform!",
                    responseHere: "The respone will arrive here!",
                    hasBeenSentToAdmins: "Your report has been sent to the administrators of the NAP platform!",
                    //Elvja tulkojumi
                    homeView: "View",
                    homeAllCategories: "All data sets",
                    homeAllDataGivers: "All data providers",
                    homeSpecifySearchContentCatalogue: "Search within catalogue...",
                    helpSpecifySearchContent: "Specify the content you are looking for in the help section...",
                    homeSpecifySearchContent: "Specify the content you are looking for",
                    auth: "Authorization",
                    youAreAuthorizedAs: "You are authorized as: ",
                    userType: "Profile type: ",
                    date: "Date",
                    from: "From",
                    status: "Status",
                    activeDataSets: "Active Data Sets",
                    inactiveDataSets: "Inactive Data Sets",
                    publishDataset: "Publish",
                    addNewDS: "Add new dataset",
                    validFrom: "Valid from:",
                    inactiveFrom: "Inactive from",
                    informationAboutTheUser: "Information about the user",
                    informationAboutTheOrganisation: "Information about the organisation",
                    editInformationAboutTheOrganisation: "Edit information about the organisation",
                    editInformationAboutContactPerson: "Edit information about the contact person",
                    company: "Company: ",
                    allEURegulas: "All legal frameworks relevant to data set or service",
                    NAPDataGivers: "NAP data providers",
                    DataGivers: "Data providers",
                    myMessages: "My inbox",
                    dataSet: "Data set",
                    dataSetUpdated: "Last updated",
                    publishedDS: "Published Data sets",
                    addDs: "Add Data set",
                    editDS: "Edit Data set",
                    myDS: "My Data sets",
                    uploadDeclaration: "Upload declaration",
                    phone: "Phone number",
                    chooseTheAppropriteDeclaration: "Choose the appropriate declaration and download it:",
                    signAndUploadTheChosenDeclaration: "Choose and upload the chosen declaration. All the available templates can be found in the",
                    helpTab: "Help tab",
                    MQTTDeclaration: "MQTT ABC Declaration",
                    otherTypeDeclaration: "Other type XYZ Declaration",
                    uploadElectornicallySignedDocument: "Upload electronically signed document",
                    active: "Active",
                    inactive: "Inactive",
                    FAQ: "Frequently Asked Questions (FAQ)",
                    AboutThePlatform: "About the platform",
                    QA: "Questions and answers",
                    errorPage1: "Perhaps the page's address has changed?",
                    aboutErrorYouCanStart: "Regarding the error, you can",
                    aboutErrorYouCanReport: "report it to the administrator",
                    pleaseWaitAWhile: "Please wait a moment.",
                    soonWillWorkAgain: "Soon everything will work again",
                    privacyPolicy: "Privacy Policy",
                    statistic: "Statistics",
                    becomeAPartner: "Become a partner",
                    mapAssetLink: "/assets/img/map1_en.png",
                    missingFields: "Missing fields: ",
                    incorrectlyFilledFields: "Incorrectly filled fields: ",
                    pathingNormalRead: "Standard reading mode",
                    handleSubscribedData: "Manage datasets you have subscribed to",
                    profileName: "Profile name: ",
                    userVeids: "User type: ",
                    userDoesntExist: "This user does not exist",
                    wrongUsernameOrPassword: "Wrong username or password!",
                    atleastOneCapitalLetter: "at least one capital letter",
                    atleastOneLowercaseLetter: "at least one lowercase letter",
                    atleastOneSpecialCharacter: "at least one special character",
                    atleastOneNumber: "at least one number",
                    atleast: "at least",
                    symbols: "symbols",
                    oldPassword: "Old Password",
                    newPassword: "New Password",
                    password: "Password",
                    passwordMustConsistOf: "Password must consist of",
                    newPasswordAgain: "New Password Again",
                    passwordAgain: "Password Again",
                    passwordsDoNotMatch: "Passwords do not match",
                    passwordUpdateFailed: "Password update failed",
                    keyExpired: "Used link has expired",

                    emptyMessageText: "Looks like you dont have any messages.",
                    emptyMessageSuggestion: "Perhaps it's worth waiting and something will come soon?",
                    pleaseDescribeMessage: "Type a message",
                    pleaseDecribeYourMessage: "Please describe the message",
                    datasetNoDeclarations: "Attention! You haven't added any declarations! ",
                    datasetNoDeclarationsLink: " My declarations",
                    areYouSureYouWantToUnsubscribe: "Are you sure you want to unsubscribe from this dataset?",
                    youCanSubscribeUsing: "You can subscribe to this dataset using the",
                    youCanViewDifferent: "You can view different datasets in the",
                    usingCatalog: "Data catalogue",
                    //Toma tulkojumi
                    areYouSureYouWantToUnpublish: "Are you sure you want to unpublish this dataset?",
                    pleaseSpecifyReasonForUnpublishing: "Please enter the reason for unpublishing",
                    awareTheDatasetWillBeDeleted: "I am aware that the dataset will be unpublished",
                    passwordChanged: "Password changed successfully!",
                    invalid_grant: "Invalid old password!",
                    invalidPasswordHistoryMessage: "Invalid password: must not be equal to any of last 3 passwords.",
                    userNotFound: "User not found!",
                    emailSent: "E-mail sent!",
                    updatedTOS: "Updated Terms of Use",
                    acceptUpdatedTOS: "Accept",
                    minInputLength: "Minimum input length is 3 characters",
                    noSubscribedDatasets: "You have not subscribed to any datasets",
                    noSubscribedDatasets2: "To subscribe to a dataset, go to the ",
                    noSubscribedDatasetsLink: "Data catalog",
                    confirmationInUse: "Confirmation in use by published dataset",
                    canAddFilesLater: "You will be able to add dataset files after saving metadata.",
                    enterYourMessage: "Please describe the message",
                    emptyDataSet: "Empty dataset",
                    siprDkNotFound: "Currently there is no data available for this dataset",
                    emailConfirmed: "Thank you, your e-mail has been verified!",
                    emailChangeConfirmed: "Thank you, your e-mail has been successfully changed!",
                    emailChangeFailed: "Failed to change email, please enter a different e-mail address.",
                    helpWorkWithPortal: "Getting started",
                    helpDN: "Data recipients",
                    helpDD: "Data providers",
                    subAndManageData: "Subscription and management of a dataset",
                    publishData: "Publishing process overview",
                    apiUseCases: "API usage and specifications",
                    reportAProblem: "Reporting problems",
                    platformContacts: "Platform contacts",
                    platformOverview: "Platform overview",
                    platformIntro: "Introduction",
                    certificates: "Declaration template (.DOCX)",
                    textCopied: "Text has been copied",
                    apiKeyCopied: "API key has been copied",
                    mqttThemeCopied: "MQTT topic has been copied",
                    accessLinkCopied: "Access link has been copied",
                    failedToUploadFile: "Failed to upload file",

                    emailAddress: "E-mail address:",
                    enterEmail: "Enter e-mail",
                    enterEmailToSendNewPassword: "Please enter the e-mail address to which the password reset link will be sent. The link will be valid for 4 hours.",
                    dataInCatalog: "Data in the catalogue",
                    sendLink: "Send link",
                    datexSchemaHelp: "DATEX II schemas",
                    datexSchemasHelpPage: "DATEX II data schemas",
                    apiSpecification: "API specifikācijas un piemēri",
                    acessLink: "Access link",
                    datasetGiverContactinfo: "Data provider contact information:",
                    usageConditions: "Usage conditions:",
                    datasetSubmittedForDeletion: "The dataset has been submitted for deletion",
                    downloadAmount: "Download amount",
                    failedToLoadDS: "Failed to load datasets",
                    datasetNotRated: "The dataset has not been rated yet",
                    cannot_publish_with_no_files: "You cannot submit a file dataset without any files!",
                    published: "Published",
                    notPublished: "Inactive",
                    withWarnings: "With warnings",
                    emailWillBeUsedForOfficialCommunication: "The e-mail will be used as a communication channel on the platform",
                
                    copied: "Copied",
                    clickToCopy: "Click to copy",

                    // manaskopas statuses
                    publish: "Published",
                    edit: "Draft",
                    submited: "Submitted",
                    deletable: "Will be deleted",
                    deleted: "Deactivated",

                    actions: "Actions",
                    warnings: "Warnings",
                    validationError: "Validation error",
                    apiDeactivated: "API deactivated",
                    activeDataSetsTooltip: "Data sets that are currently published on the portal and available to data recipients",
                    inactiveDataSetsTooltip: "Datasets under review or trial mode, as well as deactivated data sets",
                    noData: "No data found!",
                    noResults: "No results found!",

                    statusTooltip: "Dataset status - Published, Draft, Submitted, To be deleted, Deactivated",
                    datasetUpdatedTooltip: "Last update date of the dataset",
                    warningsTooltip: "Dataset warnings, validation errors, and notifications about deactivated API keys",
                    actionsTooltip: "Edit and submit dataset for publication",
                    show: "Show",
                    giverKeyInactiveDescription: "The platform administrator has deactivated your data set API key. Information about the deactivation is available in the Inbox.",

                    apiAndEndpointTooltip: "API key and endpoint for receiving data",
                    mqttServer: "MQTT server",
                }
            },
            lv: {
                translations: {
                    january: "Janvāris",
                    february: "Februāris",
                    march: "Marts",
                    april: "Aprīlis",
                    may: "Maijs",
                    june: "Jūnijs",
                    july: "Jūlijs",
                    august: "Augusts",
                    september: "Septembris",
                    october: "Oktobris",
                    november: "Novembris",
                    december: "Decembris",
                    sunShort: "Sv",
                    monShort: "P",
                    tueShort: "O",
                    wedShort: "T",
                    thuShort: "C",
                    friShort: "Pk",
                    satShort: "S",
                    sunMin: "Sv",
                    monMin: "P",
                    tueMin: "O",
                    wedMin: "T",
                    thuMin: "C",
                    friMin: "Pk",
                    satMin: "S",
                    sunday: "Svētdiena",
                    monday: "Pirmdiena",
                    tuesday: "Otrdiena",
                    wednesday: "Trešdiena",
                    thursday: "Ceturtdiena",
                    friday: "Piektdiena",
                    saturday: "Sestdiena",
                    sessionTimeOver: "Pārlūka sesijas laiks ir beidzies.",
                    closeTheBrowserBegin: "Lūdzu aizveriet pārlūku vai tā cilni, vai ", // waiting for dmitrijs fix for  " "
                    closetheBrowserEndLink: "autentificējieties atkārtoti!",
                    datasetNid: "Datu kopas identifikators:",
                    errorOccured: "Kļūda",
                    errorOccuredMessage: "Kļūda apstrādājot jūsu pieprasījumu. Lūdzu, mēģiniet vēlāk.",
                    tryAgain: "Mēģināt vēlreiz",
                    weDontHaveThisPage: "Tādas lapas mums diemžēl nav.",
                    descFieldMandatory: "Satura lauks ir obligāts!",
                    subscribeToDS: "Abonēt datu kopu",
                    system: "Sistēma",
                    system_admin: "Administrators",
                    realTimeDataStart: "ierīces nodod transporta",
                    realTimeDataEnd: "datus reālajā laikā",
                    dataInstancesStart: "datu vienības",
                    dataInstancesEnd: "uzturētas platformā",
                    dataSetsCountStart: "datu kopas ar API",
                    dataSetsCountEnd: "izmantošanas iespējām",
                    userVeids: "Lietotāja veids: ",
                    help: "Palīdzība",
                    registrationJuridicalLastName: "Kontaktpersonas uzvārds",
                    incorrectlyFilledFields: "Nepareizi aizpildītie lauki: ",
                    missingFields: "Iztrūkstošie lauki: ",
                    mapAssetLink: "/assets/img/map1.png",
                    termsOfUse: "Lietošanas noteikumi",
                    becomeAPartner: "Kļūsti par partneri",
                    statistic: "Statistika",
                    privacyPolicy: "Privātuma politika",
                    about: "Par Projektu",
                    cancel: "Atcelt",
                    datasetCoordinateSystem: "Koordināšu sistēma:",
                    datasetGeoreferencing: "Ģeoreferencēšanas metode",
                    datasetDataVersion: "Datu versija",
                    datasetNationalAssessmentDate: "Nacionālās institūcijas izvērtējuma datums",
                    datasetDatexPublicationType: "Datex publikācijas tips",
                    datasetCardValueLV0Latvija: "LV0 - Latvija",
                    // datu kopas kartiņas vērtību tulkojumi
                    datasetCardVakueReizi10minūtēs: "Reizi 10 minūtes",
                    datasetCardValueNekadvienreizējsnodrošinājums: "Nekad (vienreizējs nodrošinājums)",
                    datasetCardValuefile: "Datnes",
                    datasetCardValueapi: "Datu izmaiņu plūsma",
                    datasetCardValuebasic: "Pamata",
                    datasetCardValueenhanced: "Standarta",
                    datasetCardValueadvanced: "Paaugstināta",
                    datasetCardValueroyaltyfree: "Neatsaucama bezmaksas licence",
                    datasetCardValueOther: "Cits formāts",
                    datasetCardValueotherFieldUpdateFrequency: "Cits atjaunināšanas biežums",
                    datasetCardValueotherFieldDataFormatModel: "Citi datu formāta modeļi",
                    datasetCardValueotherFieldDataFormatSyntax: "Citas datu sintakses",
                    datasetCardValueotherFieldLicenceConditions: "Citi licencēšanas nosacījumi",
                    datasetCardValueotherFieldLegalFramework: "Citi regulējumi",
                    datasetCardValueotherFieldAccessInterface: "Citas piekļuves saskarnes",
                    datasetCardValueotherFieldServiceType: "Citi pakalpojumu veidi",
                    datasetCardValueothersFieldTransportModeCoverage: "Citi transporta veidi",
                    datasetCardValueLocationsearch: "Vietas meklēšana",
                    datasetCardValueInformationservice: "Informācijas serviss",
                    datasetCardValueTripplans: "Ceļojumu plāni",
                    datasetCardValueTripplansauxiliaryinformationavailabilitycheck: "Ceļojumu plāni, palīginformācija, pieejamības pārbaude",
                    datasetCardValueTripplancomputationscheduledmodestransport: "Ceļojuma plānošanas aprēķins - ieplānoti transporta veidi",
                    datasetCardValueDynamicPassingtimestripplansandauxiliaryinformation: "Dinamiski pārejoši laiki, ceļojumu plāni un palīginformācija",
                    datasetCardValueDynamicInformationservice: "Dinamisks informācijas serviss",
                    datasetCardValueDynamicavailabilitycheck: "Dinamiski pieejamības pārbaude",

                    datasetCardValueotherFieldCoordinateSystem: "Citas koordināšu sistēmas",
                    datasetCardValueotherFieldGeoreferencingMethod: "Citas ģeoreferencēšanas metodes",
                    datasetCardValueotherFieldTransportModeCoverage: "Citi transporta veidi",
                    datasetCardValueotherFieldDatexPublicationType: "Citi Datex publicēšanas veidi",

                    datasetCardValueNepārtraukti: "Nepārtraukti",
                    datasetCardValueJā: "Jā",
                    datasetCardValueNē: "Nē",
                    datasetCardValueDatuizmaiņuplūsma: "Datu izmaiņu plūsma",
                    datasetCardValueDatnes: "Datnes",
                    datasetCardValueAutomaģistrāles: "Automaģistrāles",
                    datasetCardValueŠtataceļivaifederālieceļi: "Štata ceļi vai federālie ceļi",
                    datasetCardValueValstsceļivaifederālieceļi: "Valsts ceļi vai federālie ceļi",
                    datasetCardValueReģionālieceļi: "Reģionālie ceļi",
                    datasetCardValuePilsētasunvietējieceļi: "Pilsētas un vietējie ceļi",
                    datasetCardValueDzelzceļatīkls: "Dzelzceļa tīkls",
                    datasetCardValueMetrotramvajuvaivieglādzelzceļatīkls: "Metro, tramvaju vai vieglā dzelzceļa tīkls",
                    datasetCardValueCitssabiedriskātransportatīkls: "Cits sabiedriskā transporta tīkls",
                    datasetCardValueŪdensceļi: "Ūdensceļi",
                    datasetCardValueGaisatīkls: "Gaisa tīkls",
                    datasetCardValueTERNtīkls: "TERN tīkls",
                    datasetCardValueTENtīkls: "TEN tīkls",
                    datasetCardValueCiti: "Citi",
                    datasetCardValueNeregulāri: "Neregulāri",
                    datasetCardValueReizi1minūtē: "Reizi 1 minūtē",
                    datasetCardValueReizi5minūtēs: "Reizi 5 minūtēs",
                    datasetCardValueReizi10minūtēs: "Reizi 10 minūtēs",
                    datasetCardValueReizi15minūtēs: "Reizi 15 minūtēs",
                    datasetCardValueReizi30minūtēs: "Reizi 30 minūtēs",
                    datasetCardValueReizi1stundā: "Reizi 1 stundā",
                    datasetCardValueReizi2stundās: "Reizi 2 stundās",
                    datasetCardValueReizi3stundās: "Reizi 3 stundās",
                    datasetCardValueReizi12stundās: "Reizi 12 stundās",
                    datasetCardValueReizi24stundās: "Reizi 24 stundās",
                    datasetCardValueReizinedēļā: "Reizi nedēļā",
                    datasetCardValueReizimēnesī: "Reizi mēnesī",
                    datasetCardValueReizi3mēnešos: "Reizi 3 mēnešos",
                    datasetCardValueReizi6mēnešos: "Reizi 6 mēnešos",
                    datasetCardValueReizigadā: "Reizi gadā",
                    datasetCardValueRetāknekāreizigadā: "Retāk nekā reizi gadā",
                    datasetCardValueNezināms: "Nezināms",
                    datasetCardValueCits: "Cits",
                    datasetCardValueAir: "Gaiss",
                    datasetCardValueBicycle: "Velosipēds",
                    datasetCardValueBikeHire: "Velosipēdu noma",
                    datasetCardValueBikeSharing: "Velosipēdu koplietošana",
                    datasetCardValueBus: "Autobuss",
                    datasetCardValueCar: "Automašīna",
                    datasetCardValueLongdistancecoach: "Tālsatiksmes autobuss",
                    datasetCardValuePrivatecar: "Privātā automašīna",
                    datasetCardValueCarHire: "Automašīnu noma",
                    datasetCardValueCarPooling: "Automašīnu koplietošana",
                    datasetCardValueCarSharing: "Automašīnu koplietošana",
                    datasetCardValueLongdistancerail: "Tālsatiksmes dzelzceļš",
                    datasetCardValueMaritimeincludingferry: "Jūras transports, ieskaitot prāmi",
                    datasetCardValueMetroSubwaytrain: "Metro / Pilsētas vilciens",
                    datasetCardValueMotorcycle: "Motocikls",
                    datasetCardValuePedestrian: "Gājējs",
                    datasetCardValueRegionalandlocalrail: "Reģionālais un vietējais dzelzceļš",
                    datasetCardValueRidePooling: "Kopbraukšana",
                    datasetCardValueShuttlebus: "Maršruta autobuss",
                    datasetCardValueShuttleferry: "Maršruta prāmis",
                    datasetCardValueTaxi: "Taksometrs",
                    datasetCardValueTramLightrail: "Tramvajs / Vieglās sliedes",
                    datasetCardValueTruck: "Kravas automašīna",
                    datasetCardValueTrolleybus: "Trolejbuss",
                    datasetCardValueNotapplicable: "Nav piemērojams",
                    datasetCardValueOthercontractualagreement: "Cits līguma veids",
                    datasetCardValueCreativeCommonsCCZero: "Creative Commons CC Zero",
                    datasetCardValueCreativeCommonsCCBY: "Creative Commons CC BY",
                    datasetCardValueCreativeCommonsCCBYSA: "Creative Commons CC BY-SA",
                    datasetCardValueCreativeCommonsCCBYND: "Creative Commons CC BY-ND",
                    datasetCardValueCreativeCommonsCCBYNC: "Creative Commons CC BY-NC",
                    datasetCardValueCreativeCommonsCCNCSA: "Creative Commons CC NC-SA",
                    datasetCardValueCreativeCommonsCCBYNCND: "Creative Commons CC BY-NC-ND",
                    datasetCardValueSoftwarelicenseofApacheSoftwareFoundation: "Apache Software Foundation programmatūras licence",
                    datasetCardValueGNUFreeDocumentationLicense: "GNU Brīvā dokumentācijas licence",
                    datasetCardValueMozillaPublicLicense: "Mozilla Publiskā licence",
                    datasetCardValueOpenDataCommonsAttributionLicenseODCBY10: "Open Data Commons Atribūcijas licence (ODC-BY 1.0)",
                    datasetCardValueOpenDataCommonsOpenDatabaseLicenseODbL: "Open Data Commons Atvērtās datubāzes licence (ODbL)",
                    datasetCardValueOpenDataCommonsPublicDomainDedicationsandLicenseODCPDDL: "Open Data Commons Publiskā domēna atzinumi un licence (PDDL)",
                    datasetCardValuePublicDomainMark10PDM: "Publiskā domēna marķējums 1.0 (PDM)",
                    datasetCardValueNotrelevant: "Nav attiecināms",
                    datasetCardValueSākotnējaisieraksts: "Sākotnējais ieraksts",
                    datasetCardValuefirst_record: "Sākotnējais ieraksts",
                    datasetAddAccessProtocol: "Piekļuves protokols:",
                    dataseteAddStandardLicence: "Standarta licence:",
                    datasetAddUpdateFreq: "Atjaunināšanas biežums:",
                    datasetAddSubCategory: "Datu satura kategorija detalizēti:",
                    datasetAddSubCategories: "Datu satura kategorijas detalizēti",
                    datasetAddCategory: "Datu satura kategorija:",
                    noOptions: "Vairs nav pieejamu opciju.",
                    datasetAddDataOwner: "Datu turētājs:",
                    datasetAddDataGiver: "Datu devēja nosaukums:",
                    datasetAddDataOwnerEmail: "Datu īpašnieka e-pasts:",
                    validUntil: "Derīgs līdz:",
                    datasetAddValidToTooltip: "Datu kopa būs pieejama līdz šim datumam",
                    datasetMetadataLanguage: "Datu kopas valoda",
                    datasetAddDate: "Metadatu izveides laiks un datums:",
                    delete: "Dzēst",
                    deleteFile: "Dzēst failu",
                    restoreFile: "Atjaunot datni",
                    unSubscribe: "Atteikties",
                    unSubscribeLong: "Atteikties no abonēšanas",
                    Declarations: "Apliecinājumi",
                    dropdownDocumentation: "Dokumentācija",
                    dropdownInbox: "Iesūtne",
                    dropdownMyProfile: "Mans profils",
                    noMessagesNo: "Nav",
                    noMessagesMessages: "jaunu ziņu",
                    noMessagesMessagesOne: "jauna ziņa",
                    noMessagesMessagesMany: "jaunas ziņas",
                    workspaceHandleUserInfo: "Pārvaldi lietotāja profila informāciju un maini paroli",
                    handleSubscribedData: "Pārvaldi datu kopas, kuras iepriekš esi abonējis",
                    headerDataServices: "DatI",
                    headerDataCatalog: "Datu katalogs",
                    fontSize: "Fonta izmērs",
                    langLat: "Latviski",
                    langEn: "English",
                    settings: "Iestatījumi",
                    contrast: "Kontrasts",
                    authorize: "Autentificēties",
                    logout: "Iziet",
                    search: "Meklēt",
                    searchPlaceHolder: "Norādiet meklēšanas kritērijus...",
                    foundStart: "Meklēt - atrast",
                    foundStartOne: "s",
                    foundStartMultiple: "i",
                    foundEnd: " rezultāt",
                    foundEndOne: "s",
                    foundEndMultiple: "i",
                    loadMore: "Ielādēt vairāk",
                    allPlatform: "Visā portālā",
                    inDataCatalog: "Datu katalogā",
                    dataCatalog: "Datu katalogs",
                    interactiveMap: "Interaktīvā karte",
                    contacts: "Kontakti",
                    copyRight: '© 2023 VSIA "Latvijas Valsts Ceļi", Nacionālais piekļuves punkts (NPP). Visas tiesības aizsargātas.',
                    titleRegister: "Reģistrēties",
                    titleCreateProfile: "Izveidot kontu",
                    titleDataSets: "Datu kopas",
                    titleDataProvidedBy: "Datus sniedz",
                    menuGreeting: "Sveiki",
                    menuProfileFeatures: "Lietotāja iespējas",
                    menuProfileFeaturesCaps: "LIETOTĀJA IESPĒJAS",
                    pathingHelp: "Palīdzība",
                    pathingEasyRead: "Viegli lasīt",
                    pathingNormalRead: "Standarta lasīšanas režīms",
                    pathingUserType: "Profila tips: ",
                    workspaceInbox: "Iesūtne",
                    workspaceMyInbox: "Mana iesūtne",
                    workspaceViewInbox: "Aplūko ienākošās ziņas un sistēmas paziņojumus",
                    workspacePublishedData: "Publicētie dati",
                    workspaceAddDataSets: "Pievieno jaunas datu kopas un pārvaldi pievienotās datu kopas",
                    workspaceDocumentation: "Dokumentācija",
                    workspaceDocumentationInfo: "Iepazīsties ar API specifikāciju un datu kopu pārvaldīšanas vadlīnijām",
                    workspaceDeclarations: "Mani apliecinājumi",
                    workspaceDeclarationsShort: "Apliecinājumi",
                    workspaceUploadDeclarations: "Augšupielādē datu apliecinājumus un pārvaldi to derīgumu",
                    workspaceMyProfile: "Mans profils",
                    workspaceProfileDescription: "Pārvaldi lietotāja profila informāciju un maini paroli",
                    workspaceCharts: "Pārskati",
                    workspaceChartsDescription: "Pārskati detalizētāku datu visualizēšanai un aplūkošanai.",
                    workspaceLogout: "Iziet",
                    workspaceLogoutDescription: "Pabeigt darbu un atslēgties no platformas",
                    workspaceSubscribedData: "Abonētās kopas",
                    workspaceSubscribedDataCaps: "ABONĒTĀS KOPAS",
                    failedToSaveData: "Neizdevās saglabāt datu kopu",
                    workspaceStart: "SĀKUMS",
                    searchResults: "Meklēšanas rezultāti",
                    userRegistration: "Lietotāja reģistrācija",
                    datasetDataGiver: "Datu devēja nosaukums:",
                    datasetDataGiverEmail: "Datu devēja e-pasts:",
                    datasetDataGiverPlain: "Datu devējs",
                    datasetUpdateFreq: "Atjaunojas:",
                    datasetDeletable: "Datu kopu paredzēts dzēst: ",
                    datasetDeletableShort: "Paredzēts dzēst: ",
                    datasetPublished: "Publicēts:",
                    datasetScore: "Vērtējums:",
                    datasetLastChanges: "Pēdējās izmaiņas:",
                    datasetChangeHistory: "Izmaiņu vēsture",
                    datasetSubscribe: "Abonēt",
                    datasetToCatalog: "Uz katalogu",
                    datasetDescription: "Datu kopas apraksts",
                    datasetViewMap: "Aplūkot uz kartes",
                    datasetLanguage: "Valoda: ",
                    languageAngļu: "Angļu",
                    languageEnglish: "Angļu",
                    languageeng: "Angļu",
                    languageLatvian: "Latviešu",
                    languageLatviešu: "Latviešu",
                    languagelav: "Latviešu",
                    datasetModel: "Datu standarts: ",
                    datasetLegalFramework: "Regulējums, kas attiecas uz datu kopu vai servisu: ",
                    datasetDistibutionMode: "Datu izplatīšanas režīms: ",
                    datasetUniqueIdentifier: "Datu kopas identifikators: ",
                    datasetAvailableFormats: "Pieejamie datņu formāti: ",
                    datasetDatexPublicationClass: "Datex publikācijas tips: ",
                    datasetAddDatexPublicationClass: "Datex publikācijas tips:",
                    datasetOverall: "Vispārīgi par datu kopu",
                    datasetReportProblem: "Atgriezeniskā saite par datu kopu", // LVC-2524 12)
                    generalFilters: "Filtri",
                    generalSorters: "Kārtošana",
                    generalFiltersAll: "Visas",
                    generalFiltersWithMap: "Ar karti",
                    generalAllCategories: "Visas kategorijas",
                    generalAllSubCategories: "Visas apakškategorijas",
                    generalAllDataFormats: "Visi datņu formāti",
                    generalAllDataModels: "Visi datu modeļi",
                    generalAllEventTypes: "Visi notikumu veidi",
                    generalAllDataTypes: "Visi datu tipu",
                    generalAllLanguages: "Visas valodas",
                    generalAllUsers: "Visi datu devēji",
                    showAll: "Rādīt visas",
                    allMapLayers: "Visi kartes slāņi",
                    currentTrafficEvents: "Aktuālie satiksmes notikumi",
                    drivingConditionsWarnings: "Braukšanas apstākļu brīdinājumi",
                    plannedEvents: "Plānoti notikumi",
                    camerasAndVehicles: "Kameras un iekārtas",
                    chargingAndFillingPoints: "Uzlādes un uzpildes punkti",
                    parkingLots: "Stāvlaukumi",
                    roadNetworkData: "Dati par ceļu tīklu",                  
                    weightAndDimensionsRestrictions: "Masas un gabarītu ierobežojumi",                  
                    generalSortByTitle: "Kārtot pēc nosaukuma",
                    generalSortByDateCreated: "Kārtot pēc izveidošanas datuma",
                    generalSortByDateUpdated: "Kārtot pēc atjaunošanas datuma",
                    generalSortByUser: "Kārtot pēc datu devēja",
                    generalOnlyApi: "Datu izmaiņu plūsma",
                    generalOnlyMap: "Tikai ar karti",
                    generalRecents: "Nesen atjaunotie (12 mēn.)",
                    generalAdvancedSearch: "Izvērstā meklēšana",
                    generalFilteredDS: "Atlasītas:",
                    generalLoadMoreResults: "Ielādēt vairāk",
                    datasetAddPublicationType: "Publicēšanas veids:",
                    datasetAddToAddInfo: "Lai sāktu datu kopas pievienošanu, izvēlieties publicējamo datu veidu",
                    datasetAddData: "Dati",
                    datasetAddInputData: "Ievadiet datus un pievienojiet datu kopas failus",
                    datasetAddInfoAboutDataset: "Informācija par datu kopu",
                    datasetAddChangeHistory: "Izmaiņu vēsture",
                    datasetAddChooseDataType: "Lūdzu izvēlieties publicējamo datu veidu:",
                    datasetAddByAddingFiles: "Pievienojot datnes",
                    datasetAddByAPI: "Datu izmaiņu plūsma (MQTT)", 
                    datasetAddCannotChange: "Vēlāk šo iestatījumu mainīt nevarēs. Nepieciešamības gadījumā būs iespēja kopēt datu kopas datus vai veidot jaunu datu kopu.",
                    datasetNoDeclarations: "Uzmanību! Jums nav pievienots neviens apliecinājums. Lai pievienotu datu kopu, vispirms nepieciešams pievienot apliecinājumu, to varat izdarīt sadaļā ",
                    datasetNoDeclarationsLink: "Mani apliecinājumi",
                    datasetAddSaveAndContinue: "Saglabāt un turpināt",
                    datasetAddInfoAboutDataGiver: "Informācija par datu devēju un kontaktpersonām",
                    datasetAddInfoAboutData: "Informācija par datiem",
                    datasetAddSave: "Saglabāt",
                    datasetAddMakeACopy: "Dublēt",
                    datasetAddDontPublishAnymore: "Dzēst (atteikties no publicēšanas)",
                    datasetAddPublicationUID: "Izplatīšanas UID: ",
                    datasetAddPublicationStatus: "Statuss: ",
                    datasetAddPublicationMode: "Režīms:",
                    datasetAddPublicationDSUID: "Datu kopas UID:",
                    datasetAddRating: "Vērtējums:",
                    datasetAddRatingCount: "Vērtējumu skaits:",
                    datasetAddImage: "Datu kopas attēls",
                    datasetAddDSLanguages: "Datu  kopas valodas",
                    basicInfo: "Pamatinformācija",
                    additionalInfo: "Papildus informācija",
                    datasetAddLatvianLanguage: "Datu kopas nosaukums latviešu valodā:",
                    datasetAddEnglishLanguage: "Datu kopas nosaukums angļu valodā:",
                    datasetAddDescriptionLatvian: "Datu kopas apraksts latviešu valodā:",
                    datasetAddDescriptionEnglish: "Datu kopas apraksts angļu valodā:",
                    fieldIsRequired: "Lauks ir obligāts. ",
                    invalidEmail: "Ievadītais e-pasts neatbilst norādītjam formātam",
                    invalidPhone: "Lūdzu, ievadiet derīgu telefona numuru (piem., +371 67000000).",
                    invalidRegNr: "Juridiskās personas reģistrācijas numuram jābūt cipariem!",
                    invalidRegNrLength: "The Juridiskās personas reģistrācijas numuram jābūt 11 cipariem!",
                    invalidWebpage: "Ievadītā mājas lapa neatbilst norādītjam formātam",
                    datasetAddDataGiverEmail: "Datu devēja e-pasts:",
                    datasetAddDataGiverWebsite: "Datu devēja tīmekļa vietne:",
                    datasetOwner: "Datu devējs:",
                    datasetAddMetadataOwner: "Metadatu turētājs:",
                    datasetAddMetadataOwnerEmail: "Metadatu turētāja e-pasts:",
                    datasetAddDeclaration: "Apliecinājums:",
                    datasetAddAgreePublishDeclaration: "Piekrītu publicēt apliecinājumu",
                    datasetAddDisagreePublishDeclaration: "Nepiekrītu publicēt apliecinājumu",
                    datasetAddAPIKey: "API atslēga:",
                    datasetCantSub: "Datu kopu bez failiem nav iespējams abonēt!",
                    datasetAPIKey: "API atslēga & Endpoint",
                    datasetAPIKeyHelp: "API atslēga & Endpoint datu saņemšanai",
                    datasetAddDownloadURL: "Aktuālo datu (snapshot) URL:",
                    datasetAddSnapshotURL: "Testa vides aktuālo datu (sandbox snapshot) URL:",
                    datasetAddSnapshotMQTTUrl: "Datu saņemšanas testu MQTT tēma:",
                    datasetAddFiles: "Pievienot vēl datnes",
                    datasetAddFile: "Pievienot failu",
                    datasetFileNotFound: "Fails nav atrasts",
                    fileDescriptionLatvian: "Faila apraksts latviski",
                    fileDescriptionEnglish: "Faila apraksts angliski",
                    errorInFile: "Kļūda failā ar nosaukumu",
                    pleaseFillAllFields: "Lūdzu aizpildiet visus laukus!",
                    cancelRestoreFile: "Atcelt datnes atjaunošanu",
                    datasetErrorNoFileDescriptionLatvian: "Lūdzu ievadiet faila nosaukumu latviski!",
                    datasetErrorNoFileDescriptionEnglish: "Lūdzu ievadiet faila nosaukumu angļu valodā!",
                    datasetErrorNoFile: "Lūdzu augšupielādējiet failu!",
                    datasetErrorCheckFile: "Faila sintakse nav derīga, salīdziniet ar izvēlētajām sintaksēm",
                    datasetErrorFilesSome: "Lūdzu pārliecinieties, ka visi faili sakrīt ar izvēlēto sintaksi!",
                    datasetErrorFailedValidation: "Faila saturs neatbilst izvēlētajai datu modeļa shēmai. Lūdzu pārbaudiet failu un mēģiniet vēlreiz!",
                    datasetErrorInvalidDate: "Ievadītais datums neatbilst formātam!",
                    validUntilShouldBeAfterValidFrom: "Ievadītais datums ir mazāks nekā datums no!",
                    topic: "Tēma",
                    datasetAddMQTTTopic: "MQTT tēma (topic):",
                    datasetMQTTTopic: "MQTT tēma (topic)",
                    datasetMQTTTopicHelp: "MQTT servera tēma datu saņemšanai",
                    datasetAddMQTTTopicSource: "MQTT tēmas avots (source MQTT topic):",
                    datasetAddPublishedFrom: "Publicēts no:",
                    datasetAddNextUpdate: "Nākamā atjaunināšana:",
                    datasetAddUpdated: "Versijas piezīmes:",
                    datasetAddDataLegalFramework: "Regulējums, kas atteicas uz datu kopu vai servisu:",
                    firstRecord: "Sākotnējais ieraksts",
                    lastUpdated: "Pēdējo reizi atjaunots",
                    before: "Pirms",
                    after: "Pēc",
                    dataSample: "Paraugs",
                    dataSampleFull: "Datu paraugs",
                    dataSetXML: "Datex II formāta datne",
                    dataSetGeoJSON: "GeoJSON vienkāršota publikācija",
                    datasetAddContentLanguage: "Datu satura valoda:",
                    datasetCardValueEnglish: "Angļu",
                    datasetCardValueLatvian: "Latviešu",
                    datasetAddGeographicalCoverage: "Ģeogrāfiskais pārklājums (NUTS):",
                    datasetAddGeographicalCoverageCountry: "Ģeogrāfiskais pārklājums (valsts):",
                    datasetAddGeographicalCoverageRegion: "Reģions:",
                    datasetAddGeographicalCoverageTooltip: "Reģions, kurā dati ir pieejami. Lauks var būt tukšs, ja dati ir pieejami visā valstī.",
                    country: "Valsts",
                    latvia: "Latvija",
                    datasetAddTransportNetworkCoverage: "Transporta tīkla pārklājums:",
                    datasetAddTransportModeCoverage: "Transporta veida pārklājums:",
                    datasetAddQualityDescription: "Kvalitātes apraksts:",
                    datasetAddCommunicationMethod: "Komunikācijas metode:",
                    datasetAddResourceType: "Resursa tips:",
                    datasetAddDataFormat: "Datu formāts:",
                    datasetAddDataSyntax: "Datu sintakse:",
                    datasetAddDataSchema: "Datu shēma:",
                    datasetAddDataModel: "Datu modelis:",
                    datasetAddDataModelSchema: "Datu modeļa shēma:",
                    datasetAddDataModelSyntax: "Sintakse:",
                    dropdownPick: "Izvēlies",
                    requiredField: "Obligāts lauks",
                    nonRequiredField: "Neobligāts lauks",
                    datasetAddDatexPublicationType: "Datex publikācijas tips:",
                    datasetAddDataExample: "Paraugs",
                    datasetUploadDataFile: "Ielādēt datu failu",
                    datasetAddDataFile: "Datu fails",
                    addedAt: "Pievienots ",
                    datasetDataSample: "Datu paraugs:",	
                    datasetUploadDataFiles: "Datnes:",
                    datasetAddDataFiles: "Pievienot datni",
                    datasetServiceType: "Servisa veids:",
                    datasetFileSize: "Maksimālais faila izmērs: 60 MB",
                    datasetFileSizeError: "Faila izmērs pārsniedz maksimālo atļauto izmēru (60 MB)",
                    dataFailedToLoad: "Datu ielāde neizdevās",
                    registrationToStartRegistration: "Lai uzsāktu reģistrāciju, lūdzu izvēlieties lietotāja veidu",
                    registrationNewUserCreation: "Jauna lietotāja izveide",
                    registrationDataRecipient: "Datu ņēmējs",
                    registrationDataProvider: "Datu devējs",
                    registrationAccessNPPData: "Piekļūst datiem, kas izvietoti NPP platformā, izmanto API un citas iespējas",
                    registrationWishToSubscribe: "Vēlos abonēt datus",
                    registrationGivesDataToNPP: "Sniedz savus datus NPP platformai, nodrošinot tiem publisku piekļuvi.",
                    registrationIWishToPublish: "Vēlos publicēt datus",
                    registrationWishToKnowMore: "GRIBI ZINĀT VAIRĀK PAR LIETOTĀJU VEIDIEM?",
                    registrationDataReciverAndGiverAdvantages: "Datu ņēmēju un devēju priekšrocības",
                    registrationProfileInfo: "Profila informācija",
                    registrationProfileInfoForm: "Izveidot jaunu lietotāju",
                    registrationProfileInfoFormDD: "Profila informācija",
                    registrationProvideEmail: "Norādiet e-pastu",
                    registrationProvideEmailForm: "Lai turpinātu, lūdzu norādiet e-pastu:",
                    registrationProvideEmailFormDD: "Lai turpinātu, lūdzu norādiet kontaktinformāciju un citus profila datus:",
                    registrationEmailTooltipFormDD: "Profila e-pasts",
                    registrationProvidePersonalInfo: "Norādiet informāciju par juridisku personu un kontaktpersonām",
                    registrationTOU: "Lietošanas noteikumi",
                    registrationConfirm: "Apstipriniet reģistrāciju",
                    registrationWeWillSendLInk: "Mēs nosūtījām apstiprinājuma saiti uz Jūsu e-pastu.",
                    registrationCreatePassword: "Izveidojiet paroli",
                    registrationProtectProfile: "Pasargājiet savu kontu ar drošu un prasībām atbilstošu paroli.",
                    registrationReadNPPRules: "Izlasiet NPP platformas noteikumus un apstipriniet tos.",
                    registrationCreateNewUser: "Izveidot jaunu lietotāju",
                    registrationCreateNewUserDD: "Izveidot jaunu lietotāju",
                    registrationPleaseInputEmail: "Lai turpinātu, lūdzu norādiet e-pastu:",
                    registrationContinue: "Turpināt",
                    resolved: "Atrisināts",
                    rejected: "Noraidīts",
                    userDoesntExist: "Šāds lietotājs neeksistē",
                    wrongUsernameOrPassword: "Lietotājvārds vai parole ievadīta nepareizi!",
                    atleastOneCapitalLetter: "vismaz viens lielais burts",
                    atleastOneLowercaseLetter: "vismaz viens mazais burts",
                    atleastOneSpecialCharacter: "vismaz viens speciālais simbols",
                    atleastOneNumber: "vismaz viens cipars",
                    atleast: "vismaz",
                    symbols: "simboli",
                    oldPassword: "Vecā parole",
                    newPassword: "Jaunā parole",
                    password: "Parole",
                    passwordMustConsistOf: "Parolei jāsatur",
                    newPasswordAgain: "Jaunā parole atkārtoti",
                    passwordAgain: "Parole atkārtoti",
                    passwordsDoNotMatch: "Paroles nesakrīt",
                    passwordUpdateFailed: "Neizdevās atjaunot paroli",
                    keyExpired: "Izmantotā saite ir novecojusi",

                    registrationUserExists: "Lietotājs ar šādu e-pasts jau reģistrēts! ",
                    registrationWrongEmail: "E-pasts ir ievadīts nekorekti!",
                    registrationReadEntire: "Nepieciešams iepazīties ar visu noteikumu tekstu un atzīmēt, ka piekrītat noteikumiem",
                    registrationJuridicalInfo: "Informācija par juridisku personu",
                    registrationJuridicalName: "Nosaukums", // Juridiskas personas nosaukums ?
                    registrationJuridicalRegNr: "Reģistrācijas numurs",
                    registrationJuridicalAddress: "Adrese",
                    registrationJuridicalWebpage: "Vietnes adrese",
                    ContactpersonData: "Kontaktpersonas dati",
                    ContactpersonFirstName: "Kontaktpersonas vārds",
                    ContactpersonLastName: "Kontaktpersonas uzvārds",
                    email: "E-pasts",
                    registrationEmail: "Kontaktpersonas e-pasts",
                    organizationEmail: "Organizācijas e-pasts",
                    emailWillBePubliclyViewable: "E-pasts tiks attēlots platformas publiskajā datu katalogā un metadatos",
                    ContactpersonPhoneNumber: "Kontaktpersonas tālrunis",
                    ContactpersonInfoAboutPublishedData: "Informācija par publicējamiem datiem",
                    ContactpersonInfoAboutPublishedDataExample: "Mēs publicējam datus par...",
                    maxSize255: "Maksimālais atļautais rakstzīmju skaits ir 255.",
                    RegistrationIAmFamiliar: "Iepazinos un piekrītu NPP platformas Lietošanas noteikumiem.",
                    registrationPassworCreation: "Paroles izveide",
                    registrationPassworCreationSecure: "Drošas paroles izveide",
                    registrationPleasePassword: "Lūdzu izveidojiet paroli, kas sastāv no vismaz 9 simboliem un ietver: lielos latīņu burtus, mazos latīņu burtus, ciparus un speciālo simbolu.",
                    registrationAlmostSafe: "Gandrīz droši, bet kaut kas tomēr pietrūkst",
                    registrationSafe: "Parole izskatās droša!",
                    registrationAccountCreated: "Lietotāja konts ir izveidots",
                    registrationAuthNow: "Ja vēlaties, autentificējieties tūlīt!",
                    registrationAuth: "Autentificēties",
                    generalFix: "Labot",
                    generalContactperson: "Kontaktpersona",
                    generalRenewPassword: "Atjaunot paroli",
                    generalChangePassword: "Nomainīt paroli",
                    generalDeleteProfile: "Dzēst kontu",
                    areYouSureYouWantToDeleteProfile: "Vai tiešām vēlaties dzēst profilu?",
                    generalIAgreeThatTheAccountWillNoLongerBeRecoverableAndIWantToDeleteIt: "Piekrītu, ka konts vairs nebūs atjaunojams un vēlos to dzēst.",
                    generalEnterDeletionReason: "Norādiet dzēšanas iemeslu",

                    popupMessageReport: "Ziņot",
                    emptyMessageText: "Izskatās ka Jums nav neviena ziņojuma.",
                    emptyMessageSuggestion: "Varbūt vērts uzgaidīt un jau drīz kas atnāks?",
                    pleaseDescribeMessage: "Ierakstiet ziņu",
                    pleaseDecribeYourMessage: "Lūdzu ievadiet savu ziņu",
                    popupMessageOrAsk: "Ziņot par kļūdu vai uzdot jautājumu",
                    popupMessageQuestion: "Jautājums",
                    popupMessageReportABug: "Kļūdas pieteikums",
                    popupMessageGenericMessage: "Vispārējs pieteikums",
                    popupMessageSend: "Nosūtīt",
                    popupMessageRegisteredUser: "Reģistrētam lietotājam",
                    popupMessageEmail: "E-pasts",
                    popupMessagePassword: "Parole",
                    popupMessageLogIn: "Ieiet",
                    popupMessageHaventRegistered: "Neesi reģistrējies?",
                    popupMessageForgotPassword: "Aizmirsi paroli?",
                    popupMessageClose: "Aizvērt",
                    popupMessageSubsciptionSuccess: "Datu kopa veiksmīgi abonēta!",
                    popupMessageSubsciptionFailed: "Neizdevās abonēt datu kopu!",
                    connectionError: "Connection error, data fetching failed",
                    cannot_sub_with_no_files: "Datu kopu bez failiem nav iespējams abonēt!",
                    popupMessageModifyProfileName: "Labot profila vārdu",
                    popupMessageModifyProfile: "Labot profilu",
                    popupMessageWebsite: "Vietnes adrese",
                    popupMessageNewDeclaration: "Jauns apliecinājums",
                    popupMessageWeCreatedDeclaration: "Mēs automātiski izveidojām apliecinājumu, balstoties uz Jūsu profila informāciju.",
                    popupMessageReadyToDownload: "Datu lejupielāde:",
                    popupMessageAddFile: "Pievienot failu",
                    popupMessageFileNotAdded: "Fails nav pievienots (.edoc, .pdf)",
                    popupMessageFileNotAddedPlain: "Fails nav pievienots",

                    popupMessagePleaseRateDataset: "Lūdzu novērtējiet datu kopas datu kvalitāti!",
                    popupMessageThanksForRateDataset: "Paldies par Jūsu atsauksmi!",
                    veikModalFollow: "Sekojiet ",
                    veikModalSubscribedData: "abonētiem datiem",
                    veikModalAnd: " un ",
                    veikModalDocumentation: "dokumentācijai",
                    veikModalYourProfile: " savā profilā.",
                    universalPopupMaxFileRequestReachedTitle: "Pārsniegts maksimālo lejupielāžu skaits!",
                    universalPopupMaxFileRequestReachedDescription: "Jūs esat pārsniedzis maksimālo atļauto lejupielāžu skaitu šai datnei.",
                    popupMessageLowRating: "1 (zems vērtējums)",
                    popupMessageHighRating: "5 (augsts vērtējums)",
                    popupMessageRate: "Novērtēt datu kopu",
                    popupMessagePleaseAnswer: "Lūdzu ierakstiet atbildi ziņojumam...",
                    popupMessageChooseStatus: "Izvēlieties statusu",
                    popupMessageConfirmDeleteFile: "Vai tiešām vēlaties dzēst publicējamo datni?",
                    popupMessageChangeSyntax: "Vai tiešām vēlaties mainīt datu kopas sintakses? Iepriekš pievienotie faili var neattiekties uz jauno sintaksi.",
                    popupMessageChangeFormat: "Vai tiešām vēlaties mainīt datu kopas formātu? Izmainot datu formātu tiks izdzēstas visas izvēlētās sintakses UN iepriekš pievienotie faili.",
                    homeDataSets: "Datu kopas",
                    homeSpecifySearchContentCatalogue: "Meklēt katalogā...",
                    helpSpecifySearchContent: "Norādiet meklēšanas kritērijus palīdzības sadaļā...",
                    homeSpecifySearchContent: "Norādiet meklējamo saturu",
                    thanksYourReportNoted: "Paldies, Jūsu pieprasījums ir pieņemts",
                    hasBeenSentToDataProvider: "Jūsu pieprasījums ir iesniegts datu devējam!",
                    hasBeenSentToDataProviderAndAdmins: "Jūsu pieprasījums ir iesniegts datu devējam un NPP platformas administrācijai!",
                    responseHere: "Gaidiet atbildi šeit!",
                    hasBeenSentToAdmins: "Jūsu pieprasījums ir iesniegts NPP platformas administrācijai!",
                    //Elvja tulkojumi
                    chooseTheAppropriteDeclaration: "1. Izvēlieties atbilstošu apliecinājumu un lejupielādējiet to:",
                    homeView: "Aplūkot",
                    date: "Datums",
                    homeAllCategories: "Visas datu kopas",
                    homeAllDataGivers: "Visi datu devēji",
                    auth: "Autentifikācija",
                    youAreAuthorizedAs: "Tu esi autorizējies kā: ",
                    userType: "Profila tips: ",
                    from: "No",
                    status: "Statuss",
                    activeDataSets: "Publicētās datu kopas",
                    inactiveDataSets: "Izmēģinājumā un deaktivizēts",
                    publishDataset: "Iesniegt",
                    addNewDS: "Jauna datu kopa",
                    validFrom: "Derīgs no:",
                    inactiveFrom: "Neaktīvs no",
                    informationAboutTheUser: "Informācija par lietotāju",
                    informationAboutTheOrganisation: "Informācija par organizāciju",
                    editInformationAboutTheOrganisation: "Labot informāciju par organizāciju",
                    editInformationAboutContactPerson: "Labot informāciju par kontaktpersonu",
                    company: "Uzņēmums: ",
                    profileName: "Profila vārds: ",
                    allEURegulas: "Visi regulējumi",
                    NAPDataGivers: "NPP datu devēji",
                    DataGivers: "Datu devēji",
                    myMessages: "Mana iesūtne",
                    dataSet: "Datu kopa",
                    dataSetUpdated: "Atjaunots",
                    publishedDS: "Publicētās datu kopas",
                    addDs: "Pievienot datu kopu",
                    editDS: "Rediģēt datu kopu",
                    myDS: "Manas datu kopas",
                    uploadDeclaration: "Pievienot jaunu apliecinājumu",
                    phone: "Tālrunis",
                    signAndUploadTheChosenDeclaration: "2. Parakstiet un augšupielādējiet izvēlēto apliecinājumu. Visu apliecinājumu sagataves pieejamas ",
                    helpTab: "palīdzības sadaļā",
                    MQTTDeclaration: "MQTT ABC Apliecinājums",
                    otherTypeDeclaration: "Otrā tipa XYZ apliecinājums.",
                    uploadElectornicallySignedDocument: "Augšupielādēt elektroniski parakstītu dokumentu",
                    active: "Aktīvs",
                    inactive: "Neaktīvs",
                    FAQ: "Biežāk uzdotie jautājumi (BUJ)",
                    AboutThePlatform: "Par platformu",
                    QA: "Jautājumi un atbildes",
                    errorPage1: "Varbūt lapai mainījās adrese?",
                    aboutErrorYouCanStart: "Par kļūdu varat",
                    aboutErrorYouCanReport: "ziņot administratoram",
                    pleaseWaitAWhile: "Lūdzam jūs nedaudz uzgaidīt.",
                    soonWillWorkAgain: "Drīz atkal viss strādās",
                    areYouSureYouWantToUnsubscribe: "Vai tiešām vēlaties atteikties no abonētās datu kopas?",
                    youCanSubscribeUsing: "Pieteikties datu kopu abonēšanai iespējams ",
                    youCanViewDifferent: "Citas datu kopas ir pieejamas ",
                    usingCatalog: "Katalogā.",
                    //Toma tulkojumi
                    areYouSureYouWantToUnpublish: "Vai tiešām vēlaties atteikties no datu kopas publicēšanas?",
                    pleaseSpecifyReasonForUnpublishing: "Lūdzu norādiet iemeslu datu kopas publicēšanas atteikumam",
                    awareTheDatasetWillBeDeleted: "Apzinos, ka tiks dzēsta publicējamā datu kopa.",
                    passwordChanged: "Parole nomainīta veiksmīgi!",
                    invalid_grant: "Nepareizi ievadīta vecā parole",
                    invalidPasswordHistoryMessage: "Nepareiza parole: nedrīkst sakrist ar pēdējām 3 iepriekšējām parolēm.",
                    userNotFound: "Lietotājs nav atrasts!",
                    emailSent: "E-pasts nosūtīts!",
                    updatedTOS: "Atjaunināti Lietošanas noteikumi",
                    acceptUpdatedTOS: "Piekrītu",
                    minInputLength: "Minimālais ievades garums ir 3 simboli",
                    noSubscribedDatasets: "Jūs neesat abonējis nevienu datu kopu",
                    noSubscribedDatasets2: "Lai abonētu datu kopu, dodieties uz ",
                    noSubscribedDatasetsLink: "Datu katalogu",
                    confirmationInUse: "Apliecienājums piesaistīts publicētai datu kopai",
                    canAddFilesLater: 'Jums būs iespēja pievienot datu kopas datnes pēc metadatu saglabāšanas.',
                    enterYourMessage: "Lūdzu ievadiet savu ziņu",
                    emptyDataSet: "Tukša datu kopa",
                    siprDkNotFound: "Dotajā brīdī datu kopā nav neviena ieraksta",
                    emailConfirmed: "Paldies, Jūsu e-pasts ir apstiprināts!",
                    emailChangeConfirmed: "Paldies, Jūsu e-pasts ir veiskmīgi nomainīts!",
                    emailChangeFailed: "Ievadīta nederīga e-pasta adrese, lūdzu meiģiniet vēlreiz ar citu!",
                    helpWorkWithPortal: "Darbs ar platformu",
                    helpDN: "Datu ņēmējiem",
                    helpDD: "Datu devējiem",
                    subAndManageData: "Datu abonēšana un pārvaldīšana",
                    publishData: "Datu publicēšana",
                    apiUseCases: "API izmantošanas scenāriji",
                    reportAProblem: "Ziņošana par problēmām",
                    platformContacts: "Platformas kontakti",
                    platformOverview: "Platformas pārskats",
                    platformIntro: "Ievads",
                    certificates: "Apliecinājuma veidne (.DOCX)",
                    textCopied: "Teksts nokopēts!",
                    apiKeyCopied: "API atslēga nokopēta!",
                    mqttThemeCopied: "MQTT tēma nokopēta!",
                    accessLinkCopied: "Piekļuves saite nokopēta!",
                    failedToUploadFile: "Neizdevās augšupielādēt failu",

                    emailAddress: "E-pasta adrese:",
                    enterEmail: "Ievadiet e-pastu",
                    enterEmailToSendNewPassword: "Lūdzu ievadiet e-pastu, uz kuru nosūtīt paroles atjaunošanas saiti. Saite būs derīga 4 stundas.",
                    dataInCatalog: "Dati katalogā",
                    sendLink: "Nosūtīt saiti",
                    datexSchemaHelp: "DATEX II datu shēmas",
                    datexSchemasHelpPage: "DATEX II datu shēmas",
                    apiSpecification: "API specifikācijas un piemēri",
                    acessLink: "Piekļuves saite",
                    datasetGiverContactinfo: "Datu devēja kontaktinformācija:",
                    usageConditions: "Izmantošanas nosacījumi:",
                    datasetSubmittedForDeletion: "Datu kopas dzēšanas pieprasījums iesniegts",
                    downloadAmount: "Lejupielāžu skaits",
                    failedToLoadDS: "Neizdevās ielādēt datu kopas",
                    datasetNotRated: "Datu kopa vēl nav novērtēta",
                    cannot_publish_with_no_files: "Datu kopu nevar publicēt bez pievienotiem failiem!",
                    published: "Publicēts",
                    notPublished: "Izmēģinājumā",
                    withWarnings: "Ar brīdinājumiem",
                    emailWillBeUsedForOfficialCommunication: "E-pasts tiks izmantots kā saziņas kanāls platformā",
                    
                    copied: "Nokopēts",
                    clickToCopy: "Peles klikšķis, lai nokopētu",
                    
                    // manaskopas statuses
                    publish: "Publicēts",
                    edit: "Melnraksts",
                    submited: "Iesniegts",
                    deletable: "Tiks dzēsts",
                    deleted: "Deaktivizēts",

                    actions: "Darbības",
                    warnings: "Brīdinājumi",
                    validationError: "Validācijas kļūda",
                    apiDeactivated: "API deaktivizēts",
                    activeDataSetsTooltip: "Datu kopas, kuras šobrīd ir publicētas portālā un pieejamas datu ņēmējiem",
                    inactiveDataSetsTooltip: "Izskatīšanā vai izmēģinājuma režīmā esošās datu kopas, kā arī deaktivizētās datu kopas",
                    noData: "Nav datu!",
                    noResults: "Nav atrasts neviens rezultāts",

                    statusTooltip: "Datu kopas statuss - Publicēts, Melnraksts, Iesniegts, Tiks dzēsts, Deaktivizēts",
                    datasetUpdatedTooltip: "Datu kopas pēdējais atjaunināšanas datums",
                    warningsTooltip: "Brīdinājumi par datu kopu, validācijas kļūdas un paziņojumi par deaktivizētu API atslēgu",
                    actionsTooltip: "Datu kopas labošana un iesniegšana publicēšanai",
                    show: "Parādīt",
                    giverKeyInactiveDescription: "Platformas administrators deaktivizēja Jūsu datu kopa API atslēgu. Informācija par deaktivizāciju pieejama Iesūtnē.",

                    apiAndEndpointTooltip: "API atslēga & Endpoint datu saņemšanai",
                    mqttServer: "MQTT serveris",
                }
            }
        },
        lng: "lv", // default language
        fallbackLng: "en",
        debug: false,

        saveMissing: true, // must be enabled
        missingKeyHandler: (lng, ns, key, fallbackValue) => {
            // console.warn(lng, ns, key, fallbackValue)
        },

        // have a common namespace used around the full app
        ns: ["translations"],
        defaultNS: "translations",

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false
        }
    });
export default i18n;
